// FAQPage.tsx
import Masonry from 'react-masonry-css';

import { useState } from "react";
import FaqItem from "../components/faq-item/faq-item";
import Switch, { SwitchOptions } from "../components/switch/switch";

const switchOptions: SwitchOptions[] = [
    { label: "Immobilienkauf", value: "1" },
    { label: "Projektübergabe", value: "2" },
];

type FAQ = {
    question: string;
    answer: string;
};

const faqs: FAQ[][] = [
    [
        {
            question: "Was passiert nach Kaufzusage?",
            answer: `
                Sende folgende Unterlagen an deinen Team-DG-Ansprechparter:<br>
                - Gut lesbare Kopie des gültigen Passes<br>
                - Handynummer<br>
                - Aktive E-Mail-Adresse<br>
                - Vollständige Anschrift <br>
                - Adressnachweis (beliebige Rechnung oder der Briefkopf)
            `
        },
        {
            question: "Muss ich zusätzlich zum Verkaufspreis noch etwas bezahlen?",
            answer: `
                - 4% DLD Fee.<br>
                - 5% der Miete / Service Charges (jährlich)<br>
                - Anwaltskosten ca. AED 5,000 + AED 3,500<br>
                - Snagging der Wohnung / 1,5 AED per sqft<br>
                - Handover Assistance Fee AED 1,000<br>
                - Tenant Management Fee / Apartment Type<br>
                - Kautionen<br>
            `
        },
        {
            question: "Was passiert, wenn ich verspätet zahle?",
            answer: "Nach Ablauf des Fälligkeitsdatums hat der Bauträger das Recht, eine Vertragsstrafe für verspätete Zahlungen zu erheben. Daher ist es ratsam, den Zahlungsplan im Blick zu behalten."
        },
        {
            question: "Was ist ein SPA?",
            answer: "Der Kaufvertrag deiner Off-Plan-Immobilie. Darin befinde sich alle Einzelheiten bezüglich deiner Immobilie (einschließlich Kaufpreis, Architekturpläne der Einheit, Fertigstellung, Spezifikationen usw.) und werden gemäß den geltenden Gesetzen Dubais in einem Dokument sowohl auf Englisch als auch auf Arabisch bereitgestellt. Eine Übersetzung wird zu einem späteren Zeitpunkt zur Verfügung gestellt."
        },
        {
            question: "Bekomme ich die Unterlagen auf Deutsch?",
            answer: "Alle Unterlagen sind auf Arabisch und Englisch.",
        },
        {
            question: "Was ist ein Oqood? Wann wird Oqood angemeldet?",
            answer: "Der Oqood ist das offizielle Dokument, das deine Eigentümerschaft an der Immobilie X bestätigt. Seine Gültigkeit erstreckt sich bis zur Übergabe des Projekts und zur Ausstellung der Eigentumsurkunde.",
        },
        {
            question: "Wer sucht einen Mieter für meine Wohnung?",
            answer: "Dies wird von unserer eigenen Property Management Firma übernommen."
        }
    ],
    [
        {
            question: "Kann ich die Endzahlung schon tätigen?",
            answer: "Du bekommst die Endabrechnung inkl. Nebenkostenabrechnung gegen Mitte Mai. Falls du schon im Voraus die Restzahlung überweisen möchtest, kannst du das gerne tun. Erfahrungsgemäß wird der überwiesene Betrag, höchstwahrscheinlich dann aber zu einer Differenz führen, so das nochmal eine Überweisung erforderlich wird."
        },
        {
            question: "Bis wann sollte ich mich entscheiden, ob ich meine Immobilie vermieten oder selbst nutzen möchte?",
            answer: "Grundsätzlich gilt: Je früher, desto besser. Bis zur Schlüsselübergabe bleibt der Ablauf jedoch gleich, unabhängig davon, ob du die Immobilie vermieten oder selbst nutzen möchtest. Aus unserer bisherigen Erfahrung mit The Haven Residences geht hervor, dass 80 % der Endnutzer ihre Immobilie innerhalb von drei bis sechs Monaten nach der Übergabe bei uns zur Miete eingestellt haben."
        },
        {
            question: "Kann ich meine Wohnung als Zweitwohnsitz nutzen?",
            answer: "Ja! Wir helfen dir bei dem Handover, übernehmen die Schlüssel für dich und wenn du das nächste Mal in Dubai bist, holst du sie einfach bei uns ab. Falls du die Wohnung später doch wieder vermieten willst, weißt du ja, wo du uns findest."
        },
        {
            question: "Kann ich den Mieter auswählen?",
            answer: `
                Wir setzen sehr hohe Standards und Anforderungen gegenüber den Mietern in unseren Projekten. Dabei berücksichtigen wir natürlich die Anregungen, die wir von euch erhalten haben.
                <br><br>
                Wir haben eine genaue Vorstellung, wie die Community in The Ivy aussehen wird und daher sind wir uns sicher, dass wir den perfekten Mieter für dich finden werden. Das spart Zeit und sorgt dafür, dass wir dir und der restlichen Investoren Community schnell und unbürokratisch einen optimalen Mieter bieten können. Außerdem gibt es Regularien, die vom Dubai Land Department vorgegeben sind, welche u.a. ein Resident Visum, eine Festanstellung sowie einen Gehaltsnachweis beinhalten. Durch diese Vorgaben wird auch unser Auswahlprozess vereinfacht. Dadurch, dass wir The Ivy sowieso nicht als günstigstes Projekt in der Region anbieten, haben wir schon eine gewisse Klientel an Mieter, welche wir mit diesem Konzept anziehen. 
            `
        },
        {
            question: "Wie bekomme ich meine Miete überwiesen?",
            answer: "Spätestens zwei Wochen nachdem der Mietcheck auf dem DGMA Konto bei der Bank  eingegangen ist, bearbeitet unsere Finanzabteilung die Auszahlung an dich. Du bekommst den Kontoauszug per E-Mail, und das Geld wird auf das in deinem Verwaltungsvertrag angegebene Bankkonto überwiesen."
        },
        {
            question: "Ich habe Mariem bei Haven eine Vollmacht erteilt, muss ich dies nochmals machen?",
            answer: "Nein. Eine Vollmacht kann man übernehmen, wenn man diese der gleichen Person wiedergibt."
        },
        {
            question: "Kann ich auf die anwaltliche Betreuung verzichten und wenn ja, welche Konsequenzen hat das?",
            answer: "Es kann keine Vertretung für dich vor Ort sein und keine Vermietung für dich getätigt werden. Außerdem nutzt du die Wohnung für dich selbst."
        },
        {
            question: "Kann ich die Miethöhe festlegen, bevor ich den Verwaltungsvertrag unterschreibe?",
            answer: "Wie im Verkaufsangebot dargestellt, sind die Mieten von uns meist schon unter kalkuliert. Wir bekommen die Wohnungen immer höher vermietet. Der Vertrag bedeutet, dass die Verwaltung die Mieten zu diesen Preisen abschließen darf, maximal 10 % darunter. Damit ist die Sicherheit des Mindest Mietpreises zu gewährleisten.",
        },
        {
            question: "Welche der aufgeführten Kosten unter 8. sind nicht einmalig und in welchem Turnus werden sie erhoben?",
            answer: "Tenant Management Fee wird im 1. Jahr mit einer Pauschale je nach Apartment Typ abgerechnet.  Ab dem 2. Jahr werden 5 % der Mieteinnahmen für die Verwaltung hergenommen. Kann auch als Einmalzahlung von der ersten Mieteinnahme genommen werden.",
        },
        {
            question: "Warum sind die Snagging/Inspektions-Kosten vom Käufer zu tragen?",
            answer: "Snagging: 1,5 AED pro sqft. 13 AED pro m2. Dies wird von einer englischen Firma übernommen, die sehr genau arbeitet. In Dubai ist es besser, es kommt eine externe Firma und kontrolliert alles genauestens, als die Baufirma, denn sie sagt schnell, dass alles passt.",
        },
        {
            question: "Wann wird die Wohnung an uns übergeben?",
            answer: "Wenn die Final Bill, Service Charges, Anschlüsse bezahlt wurden, dann gibt es die Schlüssel. Übergabe ist im IVY Büro.",
        },
        {
            question: "Kann ich aufgrund der steigenden Kaufpreise und Mieten in Dubai mit der höchstmöglichen Miete rechnen?",
            answer: "Ja! In den Verträgen sind die Mietpreise, die 2021 damals gängig waren. Seither sind die Mietpreise in der Region gestiegen. Du kannst daher mit höheren Mieten rechnen.",
        }
    ],
];

const FAQPage = () => {
    // States
    const [selectedCategory, setSelectedCategory] = useState<string>("1");

    const handleFilterClick = (value: string) => {
        setSelectedCategory(value);
    }

    const breakpointColumnsObj = {
        default: 2,  // number of columns in default
        1100: 1,     // number of columns on screens wider than 1100px
        700: 1,      // number of columns on screens wider than 700px
        500: 1       // number of columns on screens wider than 500px
    };

    return (
        <div style={{ padding: "12px 16px" }}>
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-center align-items-center">
                <Switch
                    options={switchOptions}
                    value={selectedCategory}
                    onChange={handleFilterClick}
                />
            </div>

            {/* FAQ ITEMS */}
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid row mt-5"
                columnClassName="my-masonry-grid_column col-md-6">

                {faqs[Number(selectedCategory) - 1].map((faq, index) => {
                    return (
                        <FaqItem
                            key={`faq-${selectedCategory}-${index}`}
                            title={faq.question}
                            content={faq.answer}
                        />
                    )
                })}
            </Masonry>
        </div>
    );
};

export default FAQPage;
