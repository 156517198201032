import React from 'react';
import {
    CardContainer,
    Header,
    ProgressLink,
    ProgressBar,
    ProgressStatus,
    ProgressPercentage,
    ProgressDetails,
    DetailRow,
    DetailLabel,
    DetailValue,
    ProgressContainer,
} from './construction-progress-card.styles';
import MaterialIcon from '../../../../components/material-icon';

interface ConstructionProgressCardProps {
    progress: number;
    status: string;
    completionDate: string;
    reportLink: string;
    onClickViewConstructionProgress: () => void;
}

const ConstructionProgressCard: React.FC<ConstructionProgressCardProps> = ({
    progress,
    status,
    completionDate,
    reportLink,
    onClickViewConstructionProgress
}) => {
    return (
        <CardContainer>
            <Header>
                Baufortschritt
                <ProgressLink onClick={onClickViewConstructionProgress}>
                    Baufortschritt ansehen
                    <MaterialIcon icon="arrow_outward" size={20} color="#3B82F6" style="outlined" />
                </ProgressLink>
            </Header>
            <ProgressContainer>
                <ProgressBar>
                    <div className="progress" style={{ width: `${progress}%` }} />
                </ProgressBar>
                <ProgressDetails>
                    <ProgressPercentage>{progress}%</ProgressPercentage>
                    <ProgressStatus>{status}</ProgressStatus>
                </ProgressDetails>
            </ProgressContainer>
            <DetailRow>
                <DetailLabel>Completion Date</DetailLabel>
                <DetailValue>{completionDate}</DetailValue>
            </DetailRow>
            <DetailRow>
                <DetailLabel>DLD Inspection Report</DetailLabel>
                <ProgressLink href={reportLink}>
                    Report ansehen
                    <MaterialIcon icon="open_in_new" size={20} color="#3B82F6" style="outlined" />
                </ProgressLink>
            </DetailRow>
        </CardContainer>
    );
};

export default ConstructionProgressCard;
