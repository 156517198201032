import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const DocumentsContainer = styled.div`
  padding: 12px 16px;
`;

export const Title = styled.h2`
  color: ${colorPalette.primaryDark};
  margin-bottom: 16px;
  ${typeScale.headingM}
`;

export const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.white};
  border: 1px solid #f1f0ed;
  border-radius: 8px;
`;

export const DocumentRow = styled.div`
  display: grid;
  grid-template-columns: 40px 2fr 1fr 1fr auto;
  align-items: center;
  padding: 24px 16px;
  border-bottom: 1px solid #F1F0ED;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const DocumentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.blue50};
  border-radius: 4px;
  width: 32px;
  height: 32px;
`;

export const DocumentName = styled.p`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyMD}
`;

export const DocumentDate = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
  text-align: left;
`;

export const DocumentCategory = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
  text-align: left;
`;

export const ActionIcon = styled.div`
  color: ${colorPalette.blue500};
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${colorPalette.blue700};
  }
`;
