import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import { fetchUnits } from "../../services/unitService";
import useUnitStore from "../../stores/unitStore";
import UnitCard from "../../components/unit-card/unit-card";
import { getActiveLease } from "../../utils/helpers";
import HouseAnim from "../../assets/anims/house-anim.json";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import Filter from "../../components/filter/filter";

// Import styled components
import {
    NoSelectContainer,
    Title,
    LoadingContainer,
    NoUnitsContainer,
    NoUnitsMessage,
    Row,
    Col,
    LoaderContainer,
    Subtitle,
    HeaderContainer,
    FilterContainer,
} from './my-units-page.styles';
import Banner from "../../components/banner/banner";
import GoodToKnowArticles from "../../components/good-to-know-articles/good-to-know-articles";

const MyUnitsPage = () => {
    const navigate = useNavigate();

    const {
        units,
        currentPage,
        isLoading,
        isInitialLoaded,
        moreDataAvailable,
        setUnits,
        setCurrentPage,
        setIsLoading,
        setIsInitialLoaded,
        setMoreDataAvailable
    } = useUnitStore();

    const loadUnits = useCallback(async () => {
        setIsLoading(true);
        try {
            const fetchedUnits = await fetchUnits(currentPage, 20);
            setUnits(currentPage === 1 ? fetchedUnits : [
                ...units,
                ...fetchedUnits,
            ]);
            setMoreDataAvailable(fetchedUnits.length === 20);
        } catch (error) {
            console.error('Error while fetching units:', error);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage]);

    useEffect(() => {
        loadUnits();
    }, [loadUnits]);

    // Infinite scroll fetch function
    const fetchMoreData = () => {
        if (moreDataAvailable && !isLoading) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onClickUnit = (unitId: number) => {
        navigate(`/my-units/${unitId}`);
    };

    return (
        <NoSelectContainer>
            <HeaderContainer>
                <Title>Meine Units</Title>
                <Subtitle>3 Units</Subtitle>

                <FilterContainer>
                    <Filter
                        label="Status"
                        hasDropdown
                        items={[
                            { label: "Im Bau", value: "in-progress" },
                            { label: "Handover", value: "handover" },
                            { label: "In Vermietung", value: "rented" },
                        ]}
                        onSelect={(value) => console.log('Selected:', value)}
                    />
                    <Filter label="Haven Residences" isSelected={false} onSelect={() => { }} />
                    <Filter label="The Ivy" isSelected={false} onSelect={() => { }} />
                    <Filter label="The Vybe" isSelected={false} onSelect={() => { }} />
                    <Filter label="Haven Gardens" isSelected={false} onSelect={() => { }} />
                    <Filter label="The Vyne" isSelected={false} onSelect={() => { }} />
                </FilterContainer>
            </HeaderContainer>

            {isLoading && !isInitialLoaded ? (
                <LoadingContainer>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </LoadingContainer>
            ) : units.length === 0 ? (
                <NoUnitsContainer>
                    <Lottie className="mt-4" animationData={HouseAnim} loop={false} style={{ height: 400 }} />
                    <NoUnitsMessage>Derzeit sind keine Immobilien in deinem Besitz aufgeführt.</NoUnitsMessage>
                </NoUnitsContainer>
            ) : (
                <>
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden", padding: "12px 16px" }}
                        dataLength={units.length}
                        next={fetchMoreData}
                        hasMore={moreDataAvailable}
                        loader={
                            <LoaderContainer>
                                <div className="spinner-border text-primary" role="status" />
                            </LoaderContainer>
                        }
                        scrollableTarget="content-area"
                    >
                        <Row>
                            {units.map((unit, index) => (
                                <>
                                    <Col key={index}>
                                        <UnitCard
                                            imageSrc={unit.pictures.length > 0 ? unit.pictures[0].picture_path : ""}
                                            propertyName={unit.property.property_name}
                                            title={unit.unit_number}
                                            onClick={() => onClickUnit(unit.unit_id)}
                                            purchasePrice={unit.owner?.purchase_price ?? 0}
                                            rent={getActiveLease(unit.leases)?.annual_rent_amount ?? 0}
                                            acquisitionDate={unit.owner?.created_at ?? ""}
                                            purpose_type={unit.purpose_type}
                                            constructionProgress={100}
                                            pmPackage="premium"
                                        />
                                    </Col>
                                    <Col key={index + 1}>
                                        <UnitCard
                                            imageSrc={unit.pictures.length > 0 ? unit.pictures[0].picture_path : ""}
                                            propertyName={unit.property.property_name}
                                            title={unit.unit_number}
                                            onClick={() => onClickUnit(unit.unit_id)}
                                            purchasePrice={unit.owner?.purchase_price ?? 0}
                                            rent={undefined}
                                            acquisitionDate={unit.owner?.created_at ?? ""}
                                            purpose_type={unit.purpose_type}
                                            constructionProgress={14}
                                        />
                                    </Col>
                                    <Col key={index + 2}>
                                        <UnitCard
                                            banner={(
                                                <Banner variant="warning" label="Bitte überweise die offenen Handover-Gebühren, damit wir mit dem Handover fortfahren können." />
                                            )}
                                            imageSrc={unit.pictures.length > 0 ? unit.pictures[0].picture_path : ""}
                                            propertyName={unit.property.property_name}
                                            title={unit.unit_number}
                                            onClick={() => onClickUnit(unit.unit_id)}
                                            purchasePrice={unit.owner?.purchase_price ?? 0}
                                            rent={undefined}
                                            acquisitionDate={unit.owner?.created_at ?? ""}
                                            purpose_type={unit.purpose_type}
                                            constructionProgress={100}
                                            handoverStep={3}
                                        />
                                    </Col>
                                </>
                            ))}
                        </Row>
                    </InfiniteScroll>

                    <GoodToKnowArticles />
                </>
            )}

            {/*<ActionSheetModal
                title="Action Sheet Title"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                primaryAction={{
                    label: 'Label',
                    onClick: () => alert('Primary Action'),
                }}
                secondaryAction={{
                    label: 'Label',
                    onClick: () => alert('Secondary Action'),
                }} onClose={() => { }}
            />*/}
        </NoSelectContainer>
    );
};

export default MyUnitsPage;
