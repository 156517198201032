import React from 'react';
import {
    HeaderContainer,
    TextContainer,
    Title,
    Description,
    ImageContainer,
    BuildingImage,
    CTAButtonContainer,
} from './handover-header.styles';
import HandoverHeaderImage from '../../../../assets/images/handover-header.png';
import CTAButton from '../../../../components/cta-button';

const HandoverHeader: React.FC = () => {
    return (
        <HeaderContainer className="d-flex justify-content-between align-items-center">
            <TextContainer>
                <Title>
                    Großartige Neuigkeiten! <br />
                    Deine Unit ist fast fertig.
                </Title>
                <Description>
                    Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht.
                    <br /><br />
                    Wir kümmern uns dann um den Rest – Von der Anmeldung deiner Unit bei Versorgern bis hin zur Vermietung und Instandhaltung.
                </Description>
                <CTAButtonContainer>
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Los geht's"
                        onClick={() => {
                            const servicesSection = document.getElementById('services');
                            if (servicesSection) {
                                servicesSection.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}
                        fullWidth
                    />
                </CTAButtonContainer>
            </TextContainer>
            <ImageContainer>
                <BuildingImage src={HandoverHeaderImage} alt="Building View" />
            </ImageContainer>
        </HeaderContainer>
    );
};

export default HandoverHeader;
