import styled from "styled-components";
import { typeScale, colorPalette } from "../../utils/theme";

// Container for the tag
export const TagContainer = styled.div<{
  backgroundColor: string;
  color: string;
}>`
  height: 36px;
  padding: 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  ${typeScale.bodyMD}
`;

// Optional logo on the left side
export const TagLogo = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 8px;
`;

// Tooltip icon styling
export const TagTooltipIcon = styled.span`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.white};

  &:hover {
    color: #ededed;
  }
`;
