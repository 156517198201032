import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const HeaderContainer = styled.div`
  padding: 12px 16px;
`;

export const PropertyUpdatesContainer = styled.div`
  .scroll-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  .updates-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding-bottom: 16px;
    scrollbar-width: none;
    padding-left: 16px;
    padding-right: 16px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      margin-right: 16px; /* Default margin between items */

      &:first-child {
        margin-left: 0; /* No gap before the first item */
      }

      &:last-child {
        margin-right: 0; /* No gap after the last item */
      }
    }
  }
`;

export const Title = styled.h2`
  color: ${colorPalette.primaryDark};
  margin: 0;
  ${typeScale.headingM}
`;

export const Subtitle = styled.p`
  color: ${colorPalette.textDark};
  margin: 4px 0 16px;
  ${typeScale.bodyLG}
`;

export const UpdateCard = styled.div`
  min-width: 300px;
  overflow: hidden;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const UpdateImage = styled.img`
  width: 100%;
  height: 220px;
  border-radius: 4px;
  object-fit: cover;
`;

export const UpdateContent = styled.div`
  padding: 0px;
`;

export const UpdateTitle = styled.h3`
  color: ${colorPalette.primaryDark};
  margin: 8px 0 0;
  ${typeScale.headingXS}
`;

export const UpdateDate = styled.p`
  color: ${colorPalette.blue700};
  margin: 8px 0 0 0;
  ${typeScale.bodyXSMain}
`;

export const ScrollButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 35%;
  transform: translateY(-50%);
  color: ${colorPalette.primaryDark};
  transition: background-color 0.2s ease;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.8);

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  &:first-of-type {
    left: 4px;
  }

  &:last-of-type {
    right: 4px;
  }
`;
