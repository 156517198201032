import React from 'react';
import {
    CardContainer,
    Header,
    HeaderTitle,
    ViewAllLink,
    Amount,
    Details,
    GrossReturn,
    Label,
    ExtraDetails,
    ExtraDetailItem,
    ExtraDetailLabel,
    ExtraDetailValue,
    IconWrapper,
    RentSummary,
    ToolbarContainer,
    TooltipIcon,
} from './tenancy-contract-card.styles';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import { formatMoney } from '../../../../utils/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface TenancyContractCardProps {
    annualRent: number;
    grossReturn: number;
    startDate: string;
    cheques: number;
    maintenanceFee: number;
    deposit: number;
    onViewAll: () => void;
}

const TenancyContractCard: React.FC<TenancyContractCardProps> = ({
    annualRent,
    grossReturn,
    startDate,
    cheques,
    maintenanceFee,
    deposit,
    onViewAll,
}) => {
    return (
        <CardContainer>
            <Header>
                <HeaderTitle>Mieteinnahmen</HeaderTitle>
                <ViewAllLink onClick={onViewAll}>
                    Miethistorie anzeigen
                    <MaterialIcon icon="arrow_outward" size={16} color={colorPalette.blue500} />
                </ViewAllLink>
            </Header>
            <RentSummary>
                <Amount>AED {formatMoney(annualRent)}</Amount>
                <GrossReturn>{grossReturn}%</GrossReturn>
            </RentSummary>
            <Details>
                <Label>Jahresmiete</Label>
                <Label>Bruttoredite</Label>
            </Details>
            <ExtraDetails>
                <ExtraDetailItem>
                    <IconWrapper>
                        <MaterialIcon icon="event_available" size={20} color={colorPalette.textDark} />
                    </IconWrapper>
                    <ExtraDetailLabel>Startdatum Mietvertrag</ExtraDetailLabel>
                    <ExtraDetailValue>{startDate}</ExtraDetailValue>
                </ExtraDetailItem>
                <ExtraDetailItem>
                    <IconWrapper>
                        <MaterialIcon icon="local_atm" size={20} color={colorPalette.textDark} />
                    </IconWrapper>
                    <ExtraDetailLabel>Anzahl an Cheques</ExtraDetailLabel>
                    <ExtraDetailValue>{cheques}</ExtraDetailValue>
                </ExtraDetailItem>
                <ExtraDetailItem>
                    <IconWrapper>
                        <MaterialIcon icon="cottage" size={20} color={colorPalette.textDark} />
                    </IconWrapper>
                    <ToolbarContainer>
                        Hausgeld (Geschätzt)
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Hausgeld informationen </Tooltip>}
                        >
                            <TooltipIcon>
                                <MaterialIcon icon="info" size={16} color={colorPalette.blue300} />
                            </TooltipIcon>
                        </OverlayTrigger>
                    </ToolbarContainer>
                    <ExtraDetailValue>AED {formatMoney(maintenanceFee)}</ExtraDetailValue>
                </ExtraDetailItem>
                <ExtraDetailItem>
                    <IconWrapper>
                        <MaterialIcon icon="move_to_inbox" size={20} color={colorPalette.textDark} />
                    </IconWrapper>
                    <ExtraDetailLabel>Mietkaution</ExtraDetailLabel>
                    <ExtraDetailValue>AED {formatMoney(deposit)}</ExtraDetailValue>
                </ExtraDetailItem>
            </ExtraDetails>
        </CardContainer>
    );
};

export default TenancyContractCard;
