import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  overflow: hidden;
`;

export const ImageSlider = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SliderControl = styled.button<{ direction: "left" | "right" }>`
  position: absolute;
  top: 50%;
  ${({ direction }) => direction}: 8px;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
`;

export const ActionButtonsContainer = styled.div`
  position: absolute;
  border-radius: 4px;
  bottom: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 8px;
  backdrop-filter: blur(20px);
  background: #363a4d33;
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`;

export const DetailsSection = styled.div``;

export const AttributeRow = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const AttributeItem = styled.div`
  align-items: center;
`;

export const AttributeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const AttributeLabel = styled.p`
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyXS}
  margin: 0;
`;

export const PurchaseInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 14px;
  border-top: 1px solid ${colorPalette.blue200};

  &:first-of-type {
    border-top: none;
  }
`;

export const PurchaseInfoLabel = styled.p`
  color: ${colorPalette.primaryDark};
  ${typeScale.bodySMMain}
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
`;

export const PurchaseInfoValue = styled.p`
  color: ${colorPalette.primaryDark};
  ${typeScale.bodySM}
  margin: 0;
`;
