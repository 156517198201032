import React, { ReactElement, useState } from 'react';
import { SPACES_ENDPOINT } from '../../utils/constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { formatMoney } from '../../utils/helpers';
import { UnitPurposeType } from '../../services/unitService';
import {
    UnitCardContainer,
    ImageContainer,
    UnitContent,
    UnitHeader,
    TitleTagsContainer,
    UnitTitle,
    UnitSubtitle,
    UnitDetails,
    UnitStatistics,
    UnitStatistic,
    UnitNumber,
    UnitTagsContainer,
    UnitStatus,
    UnitStatusItem,
    UnitStatisticLabel,
    UnitStatusLabel,
    Container,
    SubtitleWithIcon,
    ProgressBar,
    ProgressBarContainer,
    ProgressContainer,
    ProgressDescription,
    ProgressInfo,
    ProgressLabel,
    ProgressPercentage,
    ProgressTextWrapper
} from './unit-card.styles';
import Tag from '../tag/tag';
import { IoBedOutline, IoResizeOutline, IoPricetagsOutline, IoWaterOutline } from 'react-icons/io5';
import { MdLocationOn } from 'react-icons/md';
import { colorPalette } from '../../utils/theme';
import HandoverProgressTracker from './components/handover-progress-tracker/handover-progress-tracker';

interface UnitCardProps {
    banner?: ReactElement<any, any>,
    imageSrc?: string;
    propertyName: string;
    title: string;
    purchasePrice: number;
    rent?: number;
    constructionProgress: number,
    handoverStep?: number,
    acquisitionDate: string;
    purpose_type: UnitPurposeType;
    pmPackage?: "basic" | "premium";
    onClick: () => void;
}

const UnitCard: React.FC<UnitCardProps> = ({
    banner,
    imageSrc,
    propertyName,
    title,
    purchasePrice,
    rent,
    constructionProgress,
    handoverStep,
    acquisitionDate,
    purpose_type,
    pmPackage,
    onClick,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <Container>
            {banner && (<>{banner}</>)}
            <UnitCardContainer onClick={onClick}>
                <ImageContainer>
                    <LazyLoadImage
                        src={imageSrc ? `${SPACES_ENDPOINT}${imageSrc}` : "https://placehold.co/600x400"}
                        alt={title}
                        effect="opacity"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        onLoad={() => setImageLoaded(true)}
                    />
                </ImageContainer>
                <UnitContent>
                    <UnitHeader>
                        <TitleTagsContainer>
                            <UnitTitle>Unit {title} • {propertyName}</UnitTitle>
                            <UnitTagsContainer>
                                {rent && (
                                    <Tag label="Rented" type="unitStatus" variant="rented" />
                                )}
                                {pmPackage && (
                                    <Tag label="Premium" type="package" variant={pmPackage} tooltip="Nice premium content!" />
                                )}
                                {constructionProgress < 100 && (
                                    <Tag label="Im Bau" type="unitStatus" variant="inProgress" />
                                )}
                            </UnitTagsContainer>
                        </TitleTagsContainer>
                        <SubtitleWithIcon>
                            <MdLocationOn size={20} color={colorPalette.blue300} />
                            <UnitSubtitle>Jumeirah Village Circle</UnitSubtitle>
                        </SubtitleWithIcon>
                    </UnitHeader>
                    <UnitDetails>
                        <UnitStatistics>
                            <UnitStatistic>
                                <IoBedOutline size={20} color={colorPalette.blue300} />
                                <UnitStatisticLabel>2 Bedroom</UnitStatisticLabel>
                            </UnitStatistic>
                            <UnitStatistic>
                                <IoWaterOutline size={20} color={colorPalette.blue300} />
                                <UnitStatisticLabel>1 Bathroom</UnitStatisticLabel>
                            </UnitStatistic>
                            <UnitStatistic>
                                <IoResizeOutline size={20} color={colorPalette.blue300} />
                                <UnitStatisticLabel>780 Sqft</UnitStatisticLabel>
                            </UnitStatistic>
                            <UnitStatistic>
                                <IoPricetagsOutline size={20} color={colorPalette.blue300} />
                                <UnitStatisticLabel>AED {formatMoney(purchasePrice)}</UnitStatisticLabel>
                            </UnitStatistic>
                        </UnitStatistics>
                        {rent ? (
                            <UnitStatus>
                                <UnitStatusItem>
                                    <UnitNumber>AED {formatMoney(rent)}</UnitNumber>
                                    <UnitStatusLabel>Jahresmiete</UnitStatusLabel>
                                </UnitStatusItem>
                                <UnitStatusItem>
                                    <UnitNumber className="text-end">9%</UnitNumber>
                                    <UnitStatusLabel>Bruttorendite</UnitStatusLabel>
                                </UnitStatusItem>
                            </UnitStatus>
                        ) : constructionProgress && constructionProgress < 100 ? (
                            <ProgressContainer>
                                <ProgressInfo>
                                    <ProgressTextWrapper>
                                        <ProgressLabel>Baufortschritt:</ProgressLabel>
                                        <ProgressDescription>Foundational Work started</ProgressDescription>
                                    </ProgressTextWrapper>
                                    <ProgressPercentage>14%</ProgressPercentage>
                                </ProgressInfo>
                                <ProgressBarContainer>
                                    <ProgressBar style={{ width: `14%` }} />
                                </ProgressBarContainer>
                            </ProgressContainer>
                        ) : handoverStep ? (
                            <>
                                <HandoverProgressTracker
                                    steps={[
                                        { label: 'Vertragsabschluss', completed: true, actionRequired: false },
                                        { label: 'Zahlung Handover-Gebühren', completed: true, actionRequired: false },
                                        { label: 'Schlüsselübergabe', completed: false, actionRequired: true },
                                        { label: 'Inspektion', completed: false, actionRequired: false },
                                        { label: 'Vermietung', completed: false, actionRequired: false },
                                    ]}
                                    currentStep={2}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </UnitDetails>
                </UnitContent>
            </UnitCardContainer>
        </Container>
    );
};

export default UnitCard;
