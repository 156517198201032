import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  padding-top: 24px;
  border: 1px solid ${colorPalette.blue200};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingSMMain}
`;

export const ViewAllLink = styled.button`
  background: none;
  border: none;
  color: ${colorPalette.blue500};
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodySM}

  &:hover {
    cursor: pointer;
  }
`;

export const RentSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Amount = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingL}
  font-weight: bold;
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

export const GrossReturn = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingL}
`;

export const Label = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;

export const ExtraDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExtraDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 14px;
  border-bottom: 1px solid ${colorPalette.blue100};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  margin: 0;
  flex: 1;
  gap: 8px;
  align-items: center;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;

// Tooltip icon styling
export const TooltipIcon = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.white};

  &:hover {
    color: #ededed;
  }
`;

export const ExtraDetailLabel = styled.p`
  margin: 0;
  flex: 1;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;

export const ExtraDetailValue = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMD}
`;
