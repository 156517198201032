import React from 'react';
import {
    DocumentsContainer,
    DocumentList,
    DocumentRow,
    DocumentName,
    DocumentDate,
    DocumentCategory,
    ActionIcon,
    Title,
} from './document-section.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';

interface Document {
    id: number;
    name: string;
    date: string;
    category: string;
}

const mockDocuments: Document[] = [
    {
        id: 1,
        name: 'Oqood',
        date: '12.04.2024',
        category: 'Offizielle Dokumente',
    },
    {
        id: 2,
        name: 'Sales Purchase Agreement',
        date: '12.04.2024',
        category: 'Kaufunterlagen',
    },
];

const DocumentSection: React.FC = () => {
    return (
        <DocumentsContainer>
            <Title>Unit-Dokumente</Title>
            <DocumentList>
                {mockDocuments.map((doc) => (
                    <DocumentRow key={doc.id}>
                        <MaterialIcon icon="draft" size={24} color={colorPalette.errorDark} />
                        <DocumentName>{doc.name}</DocumentName>
                        <DocumentDate>{doc.date}</DocumentDate>
                        <DocumentCategory>{doc.category}</DocumentCategory>
                        <ActionIcon>
                            <MaterialIcon icon="file_save" style="outlined" size={24} color={colorPalette.blue500} />
                        </ActionIcon>
                    </DocumentRow>
                ))}
        </DocumentList>
        </DocumentsContainer >
    );
};

export default DocumentSection;
