import React, { useRef, useState, useEffect } from 'react';
import {
    PropertyUpdatesContainer,
    UpdateCard,
    UpdateImage,
    UpdateContent,
    UpdateTitle,
    UpdateDate,
    ScrollButton,
    Title,
    Subtitle,
    HeaderContainer
} from './property-updates.styles';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';

interface PropertyUpdate {
    id: number;
    image: string;
    title: string;
    date: string;
    category: string;
}

const mockData: PropertyUpdate[] = [
    {
        id: 1,
        image: 'https://via.placeholder.com/400x300',
        title: 'Bauupdate im Februar - Der Grundstein ist gelegt',
        date: '22.09.2024',
        category: 'Bauupdates',
    },
    {
        id: 2,
        image: 'https://via.placeholder.com/400x300',
        title: 'Fortschritt im März - Die Struktur nimmt Form an',
        date: '22.03.2024',
        category: 'Bauupdates',
    },
    {
        id: 3,
        image: 'https://via.placeholder.com/400x300',
        title: 'Bauupdate im April - Der Rohbau ist abgeschlossen',
        date: '22.04.2024',
        category: 'Bauupdates',
    },
    {
        id: 4,
        image: 'https://via.placeholder.com/400x300',
        title: 'Bauupdate im Mai - Fassadenarbeiten beginnen',
        date: '22.05.2024',
        category: 'Bauupdates',
    },
    {
        id: 5,
        image: 'https://via.placeholder.com/400x300',
        title: 'Bauupdate im Juni - Innenausbau läuft',
        date: '22.06.2024',
        category: 'Bauupdates',
    },
    {
        id: 6,
        image: 'https://via.placeholder.com/400x300',
        title: 'Bauupdate im Juli - Gartenarbeiten abgeschlossen',
        date: '22.07.2024',
        category: 'Bauupdates',
    },
];

const PropertyUpdates: React.FC = () => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const updateScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        updateScrollButtons();
        window.addEventListener('resize', updateScrollButtons);

        return () => {
            window.removeEventListener('resize', updateScrollButtons);
        };
    }, []);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -300 : 300,
                behavior: 'smooth',
            });
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0));
        setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - (scrollContainerRef.current?.offsetLeft || 0);
        const walk = (x - startX) * 1.5; // Adjust scroll sensitivity here
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const handleMouseUpOrLeave = () => {
        setIsDragging(false);
    };

    return (
        <PropertyUpdatesContainer>
            <HeaderContainer>
                <Title>Property Updates</Title>
                <Subtitle>The Vybe</Subtitle>
            </HeaderContainer>
            <div className="scroll-wrapper">
                {canScrollLeft && (
                    <ScrollButton onClick={() => scroll('left')}>
                        <MaterialIcon icon="arrow_back" size={16} color={colorPalette.textMain} />
                    </ScrollButton>
                )}
                <div
                    className="updates-container"
                    ref={scrollContainerRef}
                    onScroll={updateScrollButtons}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUpOrLeave}
                    onMouseLeave={handleMouseUpOrLeave}
                >
                    {mockData.map((update) => (
                        <UpdateCard key={update.id}>
                            <UpdateImage src={update.image} alt={update.title} draggable={false} />
                            <UpdateContent>
                                <UpdateDate>{update.date} • {update.category}</UpdateDate>
                                <UpdateTitle>{update.title}</UpdateTitle>
                            </UpdateContent>
                        </UpdateCard>
                    ))}
                </div>
                {canScrollRight && (
                    <ScrollButton onClick={() => scroll('right')}>
                        <MaterialIcon icon="arrow_forward" size={16} color={colorPalette.textMain} />
                    </ScrollButton>
                )}
            </div>
        </PropertyUpdatesContainer>
    );
};

export default PropertyUpdates;
