import React, { useCallback, useState } from 'react';
import {
    SectionContainer,
    Container,
    Title,
    Subtitle,
    UnitItem,
    UnitDetails,
    NoManagementOption,
    PropertyTitle,
    UnitTitle,
    CTAButtonContainer,
    UnitRow,
    Disclaimer,
    InfoRow,
    InfoText,
    Price,
    RightSideContainer,
} from './handover-chooser-section.styles';
import Checkbox from '../../../../components/checkbox/checkbox';
import CTAButton from '../../../../components/cta-button';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import PricingModal from '../../../../modals/pricing-modal/pricing-modal';

interface Unit {
    id: string;
    name: string;
    project: string;
    selected: boolean;
}

interface HandoverChooserSectionProps {
    noManagement: boolean;
    units: Unit[];
    onNoManagementToggle: (checked: boolean) => void;
    onUnitToggle: (updatedUnits: Unit[]) => void;
    onContinue: () => void;
}

const HandoverChooserSection: React.FC<HandoverChooserSectionProps> = ({
    noManagement,
    units,
    onNoManagementToggle,
    onUnitToggle,
    onContinue,
}) => {
    // States
    const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);

    // Helper function to toggle the "selected" state
    const handleUnitSelectionToggle = useCallback((id: string, selected: boolean) => {
        const updatedUnits = units.map(unit =>
            unit.id === id ? { ...unit, selected } : unit
        );
        onUnitToggle(updatedUnits);
    }, [units, onUnitToggle]);

    // Toggles no management and deselects all units
    const handleNoManagementToggle = useCallback((checked: boolean) => {
        onNoManagementToggle(checked);
        if (checked) {
            const updatedUnits = units.map(unit => ({
                ...unit,
                selected: false
            }));
            onUnitToggle(updatedUnits);
        }
    }, [units, onNoManagementToggle, onUnitToggle]);

    return (
        <>
            <SectionContainer>
                <Container>
                    <Title>Wähle aus, ob du Unterstützung beim Handover der Wohnung in Anspruch nehmen willst</Title>
                    <Subtitle>
                        Bevor die Wohnung vollständig an dich übertragen werden kann, muss sie zunächst vom Bauträger abgenommen werden und bei Versorgern für Strom, Wasser & Klimaanlage angemeldet werden.
                        Bitte beachte dass Kautionen nur mit einer lokalen VAE-Bankkarte bezahlt werden können.
                    </Subtitle>

                    {units.map(({ id, name, project, selected }) => (
                        <UnitItem
                            key={id}
                            disabled={noManagement}
                            onClick={() => !noManagement && handleUnitSelectionToggle(id, !selected)}
                        >
                            <UnitRow>
                                <Checkbox
                                    checked={selected}
                                    onChange={(checked: boolean) => !noManagement && handleUnitSelectionToggle(id, checked)}
                                    disabled={noManagement}
                                />
                                <UnitDetails>
                                    <UnitTitle>Handover Support</UnitTitle>
                                    <PropertyTitle>{name} - {project}</PropertyTitle>
                                </UnitDetails>
                            </UnitRow>
                            <RightSideContainer>
                                <InfoRow>
                                    <div>
                                        <Price>AED 1.000</Price>
                                        <InfoText>Einmalig, zzgl. Kautionen</InfoText>
                                    </div>
                                    <MaterialIcon icon="info" size={20} color={colorPalette.blue600} onClick={() => setIsPricingModalVisible(true)}/>
                                </InfoRow>
                            </RightSideContainer>
                        </UnitItem>
                    ))}

                    <NoManagementOption onClick={() => handleNoManagementToggle(!noManagement)}>
                        <Checkbox
                            checked={noManagement}
                            onChange={handleNoManagementToggle}
                        />
                        <span>Ich möchte keinen Handover-Support</span>
                    </NoManagementOption>

                    <CTAButtonContainer>
                        <CTAButton
                            variant="primary"
                            size="L"
                            label="Weiter"
                            onClick={onContinue}
                            fullWidth
                        />
                    </CTAButtonContainer>

                    <Disclaimer>Zu diesem Zeitpunkt wird dir noch nichts berechnet. Du kannst bequem per Rechnung bezahlen, sobald deine Anmeldung abgeschlossen ist.</Disclaimer>
                </Container>
            </SectionContainer>

            {isPricingModalVisible && (
                <PricingModal
                    type="handover"
                    onClose={() => setIsPricingModalVisible(false)}
                />
            )}
        </>
    );
};

export default HandoverChooserSection;
