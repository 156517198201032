import './root.css';

import { useState, useEffect, useMemo, useRef, Suspense } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import Topbar from '../components/topbar/topbar';
import useScrollStore from '../stores/scrollStore';
import { throttle } from 'lodash';
import useAlertStore from '../stores/alertStore';
import useInvestorDetailStore from '../stores/investorDetailStore';
import useViewportHeight from '../hooks/useViewportHeight';
import { checkSession, logout } from '../services/authService';
import useConstructionUpdateStore from '../stores/constructionUpdateStore';
import ScrollUpButton from '../components/scroll-up-button/scroll-up-button';
import useUnitDetailStore from '../stores/unitDetailStore';

const Root = () => {
    useViewportHeight();
    const navigate = useNavigate();
    const location = useLocation();
    const contentAreaRef = useRef<HTMLDivElement>(null);

    // Stores
    const { investor, reset: resetInvestoreDetailStore } = useInvestorDetailStore();
    const setAtBottom = useScrollStore(state => state.setAtBottom);
    const hideAlert = useAlertStore(state => state.hideAlert);
    const resetUnitDetailStore = useUnitDetailStore(state => state.reset);
    const resetConstructionUpdateStore = useConstructionUpdateStore(state => state.reset);

    // States
    const [lastVisible, setLastVisible] = useState<number>(Date.now());
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    // Scroll handler to track reach bottom
    useEffect(() => {
        const scrollContainer = contentAreaRef.current;
        if (scrollContainer) {
            const handleScroll = throttle(() => {
                const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
                const offsetHeight = scrollContainer.scrollHeight;
                const reachedBottom = scrollPosition >= offsetHeight - 80;
                setAtBottom(reachedBottom);
            }, 300);

            scrollContainer.addEventListener('scroll', handleScroll);

            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [setAtBottom, contentAreaRef]);

    // Hide alert after navigation
    useEffect(() => {
        hideAlert();
        if (location.pathname === "/login" || location.pathname === "/forgot-password") {
            setSidebarVisible(false);
        }
    }, [location]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setLastVisible(Date.now());
            } else if (document.visibilityState === 'visible') {
                const now = Date.now();
                const diff = now - lastVisible;

                if (diff > 1800000) { // 30 min
                    runCheckAuth();
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [lastVisible]);

    useEffect(() => {
        if (!investor) {
            navigate("/login");
        }
    }, [investor]);

    const runCheckAuth = async () => {
        try {
            await checkSession();
        } catch (error) {
            console.log('Error while checking user session: ', error);
            await logout();

            resetConstructionUpdateStore();
            resetInvestoreDetailStore();
            resetUnitDetailStore();
        }
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setLastVisible(Date.now());
            } else if (document.visibilityState === 'visible') {
                const now = Date.now();
                const diff = now - lastVisible;

                if (diff > 1800000) { // 30 min
                    runCheckAuth();
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [lastVisible, runCheckAuth]);

    useEffect(() => {
        runCheckAuth();
    }, []);

    useEffect(() => {
        if (contentAreaRef.current) {
            const isMobileView = () => window.innerWidth < 992;

            if (isMobileView()) {
                contentAreaRef.current.style.overflowY = isSidebarVisible ? 'hidden' : 'auto';
            }

            return () => {
                if (isMobileView()) {
                    document.body.style.overflow = 'auto';
                }
            };
        }
    }, [isSidebarVisible]);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const isFullPage = useMemo(() => location.pathname === "/login" || location.pathname === "/reset-password" || location.pathname === "/packages", [location]);

    return (
        <Suspense fallback={<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>}>
            <div>
                {isFullPage ? (
                    <Outlet />
                ) : (
                    <>
                        <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
                        <div id="detail">
                            <Topbar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
                            <div id="content-area" ref={contentAreaRef}>
                                <Outlet />
                                <ScrollUpButton scrollableRef={contentAreaRef} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Suspense>
    );
};

export default Root;
