import styled from "styled-components";
import { BsSearch, BsInfoCircle } from "react-icons/bs";
import { colorPalette, typeScale } from "../../utils/theme";

export const TopbarLogo = styled.img`
  width: 125px;
`;

// Navbar Container
export const Navbar = styled.nav`
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid ${colorPalette.blue200};
  background-color: white;
  height: 70px;
`;

// Navbar Title
export const NavbarTitle = styled.span`
  ${typeScale.bodyMDMain}
  color: ${colorPalette.blue600}
`;

// Navbar Toggler Button
export const NavbarToggler = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  /* Show only on mobile screens */
  @media (min-width: 992px) {
    display: none;
  }
`;

// Search Container
export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

// Search Input
export const SearchInput = styled.input`
  background-color: #f3f3f3;
  color: #b1b5c1;
  font-size: 14px;
  padding-left: 40px;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s;
  height: 40px;

  &:focus {
    background-color: #f3f3f3;
    color: #b1b5c1;
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
  }

  &::placeholder {
    color: #b1b5c1;
  }
`;

// Search Icon
export const SearchIcon = styled(BsSearch)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #b1b5c1;
  z-index: 10;
`;

// Notification Icon
export const NotificationIcon = styled(BsInfoCircle)`
  width: 24px;
  height: 24px;
  color: black;
  cursor: pointer;
`;

// User Avatar
export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
`;
