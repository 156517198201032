// DashboardPage.tsx

const DashboardPage = () => {
    return (
        <div style={{ padding: "12px 16px" }}>
            <h4>Dashboard Page</h4>
        </div>
    );
};

export default DashboardPage;
