import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from './package-selection-page.styles';
import ProgressHeader from "./components/progress-header/progress-header";
import HandoverHeader from "./components/handover-header/handover-header";
import ServicesSection from "./components/services-section/services-section";
import UnitChooserSection from "./components/unit-chooser-section/unit-chooser-section";
import PackageSelectionSection from "./components/package-selection-section/package-selection-section";
import Footer from "./components/footer/footer";
import OnboardingFormularSection from "./components/onboarding-formular-section/onboarding-formular-section";
import HandoverChooserSection from "./components/handover-chooser-section /handover-chooser-section";
import Topbar from "../../components/topbar/topbar";

interface Unit {
    id: string;
    name: string;
    project: string;
    selected: boolean;
    selfUse?: boolean;
}

const PackageSelectionPage: React.FC = () => {
    const navigate = useNavigate();

    // State to track the selected units
    const [units, setUnits] = useState<Unit[]>([
        { id: '1', name: 'Unit 813', project: 'The Vyne', selected: false, selfUse: false },
        { id: '2', name: 'Unit 1401', project: 'The Vyne', selected: false, selfUse: false },
    ]);

    const [handoverUnits, setHandoverUnits] = useState<Unit[]>([
        { id: '1', name: 'Unit 813', project: 'The Vyne', selected: false },
        { id: '2', name: 'Unit 1401', project: 'The Vyne', selected: false },
    ]);

    // State to track the "no management" option
    const [noManagement, setNoManagement] = useState(false);

    // Handle toggling of units, passing the entire updated list
    const handleUnitToggle = (updatedUnits: Unit[]) => {
        setUnits(updatedUnits);
    };

    // Handle toggling of units, passing the entire updated list
    const handleHandoverUnitToggle = (updatedUnits: Unit[]) => {
        setHandoverUnits(updatedUnits);
    };

    // Handle "no management" toggle
    const handleNoManagementToggle = (checked: boolean) => {
        setNoManagement(checked);

        // If "no management" is checked, deselect all units
        if (checked) {
            const updatedUnits = units.map(unit => ({
                ...unit,
                selected: false,
                selfUse: false,
            }));
            setUnits(updatedUnits);
        }
    };

    // Handle continue button click
    const handleContinue = () => {
        const selectedUnits = units.filter(unit => unit.selected);
        if (selectedUnits.length > 0 || noManagement) {
            alert('Proceeding to the next step');
        } else {
            alert('Please select a unit or choose no management.');
        }
    };

    return (
        <>
            <Container>
                <Topbar
                    isFullPage
                    isSidebarVisible={false}
                    toggleSidebar={() => { }}
                />

                <ProgressHeader
                    title="Mietmanagement Paket auswählen"
                    onBack={() => navigate(-1)}
                    onHelpClick={() => alert('Contact us')}
                    isLoading={true}
                />

                <HandoverHeader />

                <ServicesSection />

                <UnitChooserSection
                    noManagement={noManagement}
                    units={units}
                    onNoManagementToggle={handleNoManagementToggle}
                    onUnitToggle={handleUnitToggle}
                    onContinue={handleContinue}
                />

                <PackageSelectionSection />

                <HandoverChooserSection
                    noManagement={noManagement}
                    units={handoverUnits}
                    onNoManagementToggle={handleNoManagementToggle}
                    onUnitToggle={handleHandoverUnitToggle}
                    onContinue={handleContinue}
                />

                <OnboardingFormularSection onContinue={() => { }} />

                <Footer />
            </Container>
        </>
    );
};

export default PackageSelectionPage;
