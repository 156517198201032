import React from 'react';
import {
    CardContainer,
    Header,
    ProgressLink,
    ProgressBar,
    ProgressSegment,
    ProgressStatus,
    ProgressPercentage,
    ProgressDetails,
    DetailRow,
    DetailLabel,
    DetailValue,
    ProgressContainer,
} from './payment-plan-card.styles';
import MaterialIcon from '../../../../components/material-icon';
import { formatMoney } from '../../../../utils/helpers';

interface PaymentPlanCardProps {
    progress: number; // e.g., 15 for 15%
    purchasePrice: number;
    nextPaymentAmount: number;
    nextPaymentDate: string;
    viewProgressLink: string;
    onClickViewPaymentPlan: () => void;
}

const PaymentPlanCard: React.FC<PaymentPlanCardProps> = ({
    progress,
    purchasePrice,
    nextPaymentAmount,
    nextPaymentDate,
    viewProgressLink,
    onClickViewPaymentPlan
}) => {
    const segments = 10;
    const filledSegments = Math.floor(progress / 10);
    const partialFill = (progress % 10) / 10;

    return (
        <CardContainer>
            <Header>
                Payment-Plan
                <ProgressLink onClick={onClickViewPaymentPlan}>
                    Payment-Plan ansehen
                    <MaterialIcon icon="arrow_outward" size={20} color="#3B82F6" style="outlined" />
                </ProgressLink>
            </Header>
            <ProgressContainer>
                <ProgressBar>
                    {Array.from({ length: segments }).map((_, index) => (
                        <ProgressSegment
                            key={index}
                            filled={index < filledSegments}
                            partial={index === filledSegments ? partialFill : 0}
                        />
                    ))}
                </ProgressBar>
                <ProgressDetails>
                    <ProgressPercentage>{progress}%</ProgressPercentage>
                    <ProgressStatus>
                        <span className="current-amount">
                            AED {formatMoney((progress * purchasePrice) / 100)}
                        </span>
                        <span className="divider"> / </span>
                        <span className="total-amount">
                            AED {formatMoney(purchasePrice)}
                        </span>
                    </ProgressStatus>
                </ProgressDetails>
            </ProgressContainer>
            <DetailRow>
                <DetailLabel>Next Payment</DetailLabel>
                <DetailValue>AED {formatMoney(nextPaymentAmount)}</DetailValue>
            </DetailRow>
            <DetailRow>
                <DetailLabel>Next Payment Due Date</DetailLabel>
                <DetailValue>{nextPaymentDate}</DetailValue>
            </DetailRow>
        </CardContainer>
    );
};

export default PaymentPlanCard;
