import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const Header = styled.div`
  padding: 12px 16px 12px 16px;
  gap: 16px;
`;

export const HeaderTitle = styled.h4`
  color: ${colorPalette.textMain};
  ${typeScale.headingL}
`;

export const HeaderSubtitle = styled.p`
  color: ${colorPalette.textDark};
  ${typeScale.bodyXL}
`;
