import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const SidebarContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: ${colorPalette.blue100};
  z-index: 1045;
  transition: transform 0.3s ease-in-out;

  /* Always visible on desktop and tablet */
  @media (min-width: 992px) {
    transform: translateX(0); /* Show sidebar */
  }

  @media (max-width: 992px) {
    width: calc(100% - 32px);
    top: 81px;
    left: 16px;
    background-color: ${colorPalette.white};
    height: calc(100 * var(--vh) - 101px);
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
    transform: ${({ isVisible }) =>
      isVisible ? "translateX(0)" : "translateX(-105%)"};
  }
`;

export const SidebarHeader = styled.div`
  height: 70px;
  line-height: 70px;
  padding-left: 16px;
`;

// Container for the navigation items
export const NavContainer = styled.ul`
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const NavItem = styled.li<{ isActive: boolean; isDeactivated: boolean }>`
  list-style-type: none;
  background-color: ${({ isActive }) =>
    isActive ? colorPalette.blue200 : "transparent"};

  a {
    color: ${({ isDeactivated }) =>
      isDeactivated ? colorPalette.blue800 : colorPalette.blue800} !important;
    ${typeScale.bodyMDMain}
    display: flex;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    padding: 24px 16px;
    gap: 12px;
    opacity: ${({ isDeactivated }) => (isDeactivated ? "0.3" : "1")};
    cursor: ${({ isDeactivated }) => (isDeactivated ? "default" : "pointer")};
  }

  &:hover {
    opacity: ${({ isActive, isDeactivated }) =>
      !isActive && !isDeactivated ? "0.7" : "1"};
  }
`;

export const LogoutButton = styled.li`
  a {
    color: ${colorPalette.errorDark} !important;
    margin: 0;
  }
`;

export const LogoImage = styled.img`
  width: 125px;
`;
