import React from 'react';
import {
    FooterContainer,
    FooterLogo,
    FooterLinksContainer,
    FooterLink,
} from './footer.styles';
import LogoIcon from '../../../../assets/images/logo/logo-dark.png';

const Footer: React.FC = () => {
    return (
        <FooterContainer className="d-flex justify-content-between align-items-center">
            <FooterLogo src={LogoIcon} alt="Logo" />
            <FooterLinksContainer className="d-flex">
                <FooterLink href="#">Link</FooterLink>
                <FooterLink href="#">Link</FooterLink>
                <FooterLink href="#">Link</FooterLink>
            </FooterLinksContainer>
        </FooterContainer>
    );
};

export default Footer;
