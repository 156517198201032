import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  padding: 24px 16px;
  border: 1px solid ${colorPalette.blue200};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingSMMain}
`;

export const ViewAllLink = styled.button`
  background: none;
  border: none;
  color: ${colorPalette.blue500};
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodySM}

  &:hover {
    cursor: pointer;
  }
`;

export const Amount = styled.p`
  margin: 0;
  color: ${colorPalette.successMedium};
  ${typeScale.headingM}
  font-weight: bold;
`;

export const StatusBadge = styled.span`
  background-color: ${colorPalette.successMedium};
  color: ${colorPalette.white};
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 8px;
  ${typeScale.bodyXS}
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DueDate = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}
`;

export const Label = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;
