// UnitDetailPage.tsx
import { useEffect, useMemo, useState } from "react";
import Lottie from "lottie-react";
import { useParams } from "react-router-dom";
import _ from 'lodash';
import useAlertStore from "../../stores/alertStore";
import CustomButton from "../../components/custom-button/custom-button";
import { fetchUnit } from "../../services/unitService";
import useUnitDetailStore from "../../stores/unitDetailStore";
import { getActiveLease, getCompletedLeases } from "../../utils/helpers";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import DocumentSection from "../../components/document-section/document-section";
import ConstructionProgressCard from "./components/construction-progress-card/construction-progress-card";
import { Header, HeaderSubtitle, HeaderTitle } from "./unit-detail-page.styles";
import Tag from "../../components/tag/tag";
import PaymentPlanCard from "./components/payment-plan-card/payment-plan-card";
import GoodToKnowArticles from "../../components/good-to-know-articles/good-to-know-articles";
import PropertyUpdates from "../../components/property-updates/property-updates";
import UnitDetailsCard from "./components/unit-details-card/unit-details-card";
import EmptyTenantCard from "./components/empty-tenant-card/empty-tenant-card";
import ConstructionProgressModal from "../../modals/construction-progress-modal/construction-progress-modal";
import PaymentPlanModal from "../../modals/payment-plan-modal/payment-plan-modal";
import CompletionPromptCard from "./components/completion-prompt-card/completion-prompt-card";
import HandoverChecklistCard from "./components/handover-checklist-card/handover-checklist-card";
import NextRentPaymentCard from "./components/next-rent-payment-card/next-rent-payment-card";
import TenancyContractCard from "./components/tenancy-contract-card/tenancy-contract-card";

const UnitDetailPage = () => {
    let { unitId } = useParams();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        unit,
        isInitialLoaded,
        isLoading,
        setUnit,
        setIsInitalLoaded,
        setIsLoading,
    } = useUnitDetailStore();

    // States
    const [isConstructionProgressModalVisible, setIsConstructionProgressModalVisible] = useState(false);
    const [isPaymentPlanModalVisible, setIsPaymentPlanModalVisible] = useState(false);

    useEffect(() => {
        if (unitId) {
            const loadUnit = async () => {
                try {
                    setIsLoading(true);
                    const unit = await fetchUnit(Number(unitId));
                    setUnit(unit);
                    setIsInitalLoaded(true);
                } catch (error) {
                    console.log(`Error while fetching unit data:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            loadUnit();
        }
    }, [unitId]);

    const onClickReportProblem = () => {
        alert("Funktion bald Verfügbar!");
    }

    const currentAnnualRent = useMemo(() => {
        if (!unit) return 0;

        const activeLease = getActiveLease(unit.leases);
        if (activeLease) {
            return activeLease.annual_rent_amount;
        }

        const leaseHistory = getCompletedLeases(unit.leases);
        return leaseHistory.length > 0 ? leaseHistory[0].annual_rent_amount : 0;
    }, [unit]);

    return (
        <div className="no-select">
            {isLoading && !isInitialLoaded ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                unit && (
                    <>
                        {/* HEADER */}
                        <Header>
                            <div className="d-flex justify-content-items align-items-center" style={{ gap: 16 }}>
                                <HeaderTitle className="m-0">Unit {unit.unit_number}</HeaderTitle>
                                <Tag
                                    label="In Progress"
                                    type="unitStatus"
                                    variant="inProgress"
                                />
                            </div>
                            <HeaderSubtitle>{unit.property.property_name}</HeaderSubtitle>
                        </Header>

                        <div className="container-fluid" style={{ padding: "16px 12px" }}>
                            {/* UNIT OVERVIEW */}
                            <div className="row gy-4">
                                <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                    <div className="row gy-4">
                                        <div className="col-12">
                                            <UnitDetailsCard
                                                images={[
                                                    'https://haven-residences.com/wp-content/uploads/2022/03/Dubai-JVC-Haven-Residences-Photos-010.jpg',
                                                    'https://haven-residences.com/wp-content/uploads/2022/03/Haven-02.jpg',
                                                    'https://haven-residences.com/wp-content/uploads/2022/04/Dubai-JVC-Haven-Residences-Photos-028.jpg',
                                                    'https://haven-residences.com/wp-content/uploads/2022/03/Dubai-JVC-Haven-Residences-Photos-019.jpg'
                                                ]}
                                                bedrooms={1}
                                                bathrooms={1}
                                                size={780}
                                                purchaseDate="20.09.2022"
                                                purchasePrice={799000}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                    <div className="row gy-4">
                                        <div className="col-12">
                                            <NextRentPaymentCard
                                                amount={20000}
                                                status="Cheque empfangen"
                                                dueDate="20.09.2024"
                                                onViewAll={() => console.log('View all transactions')}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TenancyContractCard
                                                annualRent={80000}
                                                grossReturn={9}
                                                onViewAll={() => console.log('View all tenancy contracts')}
                                                startDate={"20.10.2024"}
                                                cheques={4}
                                                maintenanceFee={12000}
                                                deposit={2500}
                                            />
                                        </div>
                                        {/*<div className="col-12">
                                            <HandoverChecklistCard
                                                checklistItems={[
                                                    {
                                                        title: 'PM Package Selection & Contract Signing',
                                                        status: 'completed',
                                                    },
                                                    {
                                                        title: 'Unit Inspection & Snagging',
                                                        details: 'Die Inspektion der Unit wurde erfolgreich durchgeführt. Falls Mängel festgestellt wurden, werden diese zeitnah behoben.',
                                                        status: 'completed',
                                                    },
                                                    {
                                                        title: 'Payment of Handover Charges',
                                                        details: 'Bitte überweise die offenen Gebühren, damit wir mit dem Handover fortfahren können.',
                                                        status: 'action',
                                                    },
                                                    {
                                                        title: 'Utility Connection',
                                                        status: 'pending',
                                                    },
                                                    {
                                                        title: 'Ownership Transfer & Title Deed',
                                                        status: 'pending',
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <CompletionPromptCard />
                                        </div>
                                        <div className="col-12">
                                            <EmptyTenantCard />
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6">
                                    <ConstructionProgressCard
                                        progress={14}
                                        status="Foundational Work started"
                                        completionDate="14.06.2028"
                                        reportLink="/reports/dld-inspection"
                                        onClickViewConstructionProgress={() => setIsConstructionProgressModalVisible(true)}
                                    />
                                </div>
                                <div className="col-6">
                                    <PaymentPlanCard
                                        progress={14}
                                        purchasePrice={899000}
                                        nextPaymentAmount={70000}
                                        nextPaymentDate="14.01.2028"
                                        viewProgressLink="/progress"
                                        onClickViewPaymentPlan={() => setIsPaymentPlanModalVisible(true)}
                                    />
                                </div>
                            </div>
                        </div>

                        <DocumentSection />
                        <GoodToKnowArticles />
                        <PropertyUpdates />
                    </>
                )
            )}

            {isConstructionProgressModalVisible && (
                <ConstructionProgressModal
                    title="Baufortschritt: The Vyne"
                    subtitle="Geplante Fertigstellung: September 2027"
                    completionDate="September 2027"
                    progressItems={[
                        {
                            percentage: 71,
                            date: '21.08.2024',
                            status: 'Main Contract: Progressing',
                            reportLink: '#',
                        },
                        {
                            percentage: 40,
                            date: '26.02.2024',
                            status: 'Main Contract: Progressing',
                            reportLink: '#',
                        },
                        {
                            percentage: 18,
                            date: '04.09.2023',
                            status: 'Foundational Work Complete',
                            reportLink: '#',
                        },
                        {
                            percentage: 4,
                            date: '22.02.2023',
                            status: 'Work Commenced',
                            reportLink: '#',
                        },
                    ]}
                    onClose={() => setIsConstructionProgressModalVisible(false)}
                />
            )}

            {isPaymentPlanModalVisible && (
                <PaymentPlanModal
                    completionDate="September 2027"
                    paymentPlanItems={[
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Fälligkeit: Rate 1',
                            title: 'Juli 2024',
                        },
                        {
                            percentage: 4,
                            amount: 31690,
                            status: 'Fälligkeit: Kaufnebenkosten (DLD Fees)',
                            title: 'Juli 2024',
                        },
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Fälligkeit: Rate 2',
                            title: 'Oktober 2024',
                        },
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Fälligkeit: Rate 3',
                            title: 'Januar 2025',
                        },
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Fälligkeit: Rate 4',
                            title: 'April 2025',
                        },
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Rate 5',
                            title: 'August 2025',
                        },
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Rate 6',
                            title: 'Dezember 2025',
                        },
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Fälligkeit: Rate 4',
                            title: 'April 2025',
                        },
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Rate 5',
                            title: 'August 2025',
                        },
                        {
                            percentage: 10,
                            amount: 79000,
                            status: 'Rate 6',
                            title: 'Dezember 2025',
                        },
                    ]}
                    onClose={() => setIsPaymentPlanModalVisible(false)}
                />
            )}
        </div>
    );
};

export default UnitDetailPage;
