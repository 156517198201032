import React from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import { BannerContainer, Label, IconWrapper } from './banner.styles';

interface BannerProps {
    variant: 'warning' | 'info' | 'neutral';
    label: string;
}

const Banner: React.FC<BannerProps> = ({ variant, label }) => {
    const test = (
        <BannerContainer variant={variant}>
            <IconWrapper variant={variant}>
                <IoWarningOutline />
            </IconWrapper>
            <Label variant={variant}>{label}</Label>
        </BannerContainer>
    );
    return test
};

export default Banner;
