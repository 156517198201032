import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const GoodToKnowContainer = styled.div`
  padding: 12px 16px;
`;

export const Title = styled.h2`
  color: ${colorPalette.primaryDark};
  margin-bottom: 16px;
  ${typeScale.headingM}
`;

export const ArticleCard = styled.div`
  display: flex;
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  margin-bottom: 16px;
  height: 160px;
`;

export const ArticleImage = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;
`;

export const ArticleContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

export const ArticleTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const ArticleTitle = styled.h3`
  color: ${colorPalette.primaryDark};
  margin: 4px 0;
  ${typeScale.headingSM}
`;

export const ArticleSubtitle = styled.p`
  color: ${colorPalette.blue300};
  margin: 0;
  ${typeScale.bodyXSMain}
`;

export const ArticleAdditionalText = styled.p`
  color: ${colorPalette.textDark};
  margin: 0;
  ${typeScale.bodyXSMain}
`;

export const LearnMore = styled.p`
  color: ${colorPalette.blue700};
  margin-top: auto;
  margin-bottom: 0;
  cursor: pointer;
  ${typeScale.bodyMDMain}

  &:hover {
    text-decoration: underline;
  }
`;
