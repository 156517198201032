import React from 'react';
import { typeScale, colorPalette } from '../utils/theme';
import MaterialIcon from './material-icon';
import { MaterialSymbol } from 'material-symbols';

interface ButtonProps {
    variant: 'primary' | 'secondary' | 'tertiary' | 'ghost';
    size: 'L' | 'M' | 'S';
    label: string;
    disabled?: boolean;
    icon?: MaterialSymbol;
    iconStyle?: 'outlined' | 'round' | 'sharp' | 'two-tone' | 'filled';
    fullWidth?: boolean;
    onClick?: () => void;
}

// Button Size Definitions
const buttonSizeStyles = {
    L: {
        padding: '12px 24px',
        fontSize: typeScale.bodyMD.fontSize,
        fontWeight: typeScale.bodyMD.fontWeight,
    },
    M: {
        padding: '12px 24px',
        fontSize: typeScale.bodySM.fontSize,
        fontWeight: typeScale.bodySM.fontWeight,
    },
    S: {
        padding: '8px 16px',
        fontSize: typeScale.bodySM.fontSize,
        fontWeight: typeScale.bodySM.fontWeight,
    },
};

// Button Color Styles for Different Variants
const buttonColorStyles = {
    primary: {
        backgroundColor: colorPalette.blue800,
        color: colorPalette.white,
        border: 'none',
    },
    secondary: {
        backgroundColor: colorPalette.primaryLight,
        color: colorPalette.blue800,
        border: 'none',
    },
    tertiary: {
        backgroundColor: colorPalette.blue200,
        color: colorPalette.blue800,
        border: 'none',
    },
    ghost: {
        backgroundColor: 'transparent',
        color: colorPalette.blue500,
        border: 'none',
    },
};

// Hover Styles for Different Variants
const hoverStyles = {
    primary: { backgroundColor: colorPalette.blue700 },
    secondary: { backgroundColor: colorPalette.blue300 },
    tertiary: { backgroundColor: colorPalette.blue300 },
    ghost: { backgroundColor: 'transparent' },
};

// Pressed Styles for Different Variants
const pressedStyles = {
    primary: { backgroundColor: colorPalette.blue900 },
    secondary: { backgroundColor: colorPalette.blue200 },
    tertiary: { backgroundColor: colorPalette.blue200 },
    ghost: { backgroundColor: 'transparent' },
};

// Disabled Styles for Different Variants
const disabledStyles = {
    primary: { opacity: '30%', cursor: 'not-allowed' },
    secondary: { opacity: '25%', cursor: 'not-allowed' },
    tertiary: { opacity: '25%', cursor: 'not-allowed' },
    ghost: { backgroundColor: 'transparent', cursor: 'not-allowed' },
};

const CTAButton: React.FC<ButtonProps> = ({
    variant,
    size,
    label,
    disabled,
    icon,
    iconStyle = 'outlined',
    fullWidth = false,
    onClick,
}) => {
    const baseStyles = {
        ...buttonSizeStyles[size],
        ...buttonColorStyles[variant],
        borderRadius: '4px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        transition: 'background-color 0.2s ease',
        width: fullWidth ? '100%' : 'auto',
    };

    const hover = hoverStyles[variant];
    const pressed = pressedStyles[variant];
    const disabledStyle = disabled ? disabledStyles[variant] : {};

    // Event handlers for hover and pressed states
    const [isPressed, setPressed] = React.useState(false);

    const handleMouseDown = () => {
        if (!disabled) setPressed(true);
    };

    const handleMouseUp = () => {
        if (!disabled) setPressed(false);
    };

    return (
        <button
            style={{
                ...baseStyles,
                ...(isPressed ? pressed : {}),
                ...(disabled ? disabledStyle : {}),
            }}
            onMouseOver={(e) => {
                if (!disabled && !isPressed) {
                    Object.assign(e.currentTarget.style, hover);
                }
            }}
            onMouseLeave={(e) => {
                if (!disabled) {
                    Object.assign(e.currentTarget.style, baseStyles);
                }
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onClick={onClick}
            disabled={disabled}
        >
            {/* Render the icon if provided */}
            {variant !== 'ghost' && icon && (
                <MaterialIcon
                    icon={icon}
                    style={iconStyle}
                    size={24}
                    color={buttonColorStyles[variant].color}
                    marginRight="8px"
                />
            )}
            {label}
            {variant === 'ghost' && icon && (
                <MaterialIcon
                    icon={icon}
                    style={iconStyle}
                    size={24}
                    color={buttonColorStyles[variant].color}
                    marginLeft={size === "L" ? "8px" : "4px"}
                />
            )}
        </button>
    );
};

export default CTAButton;
