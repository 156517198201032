import React from 'react';
import {
    CardContainer,
    ImageSlider,
    SliderControl,
    DetailsSection,
    AttributeRow,
    AttributeItem,
    AttributeIcon,
    AttributeLabel,
    PurchaseInfoRow,
    PurchaseInfoLabel,
    PurchaseInfoValue,
    ActionButton,
    ActionButtonsContainer,
} from './unit-details-card.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';

interface UnitDetailsCardProps {
    images: string[];
    bedrooms: number;
    bathrooms: number;
    size: number; // in sqft
    purchaseDate: string;
    purchasePrice: number;
}

const UnitDetailsCard: React.FC<UnitDetailsCardProps> = ({
    images,
    bedrooms,
    bathrooms,
    size,
    purchaseDate,
    purchasePrice,
}) => {
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    const handlePrevious = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <CardContainer>
            <ImageSlider>
                <img src={images[currentImageIndex]} alt={`Unit Image ${currentImageIndex + 1}`} />
                <SliderControl direction="left" onClick={handlePrevious}>
                    <MaterialIcon icon="arrow_back" size={24} color={colorPalette.white} />
                </SliderControl>
                <SliderControl direction="right" onClick={handleNext}>
                    <MaterialIcon icon="arrow_forward" size={24} color={colorPalette.white} />
                </SliderControl>
                <ActionButtonsContainer>
                    <ActionButton>
                        <MaterialIcon icon="3d_rotation" size={20} color={colorPalette.white} />
                    </ActionButton>
                    <ActionButton>
                        <MaterialIcon icon="zoom_out_map" size={20} color={colorPalette.white} />
                    </ActionButton>
                </ActionButtonsContainer>
            </ImageSlider>
            <DetailsSection>
                <AttributeRow>
                    <AttributeItem>
                        <AttributeIcon>
                            <MaterialIcon icon="bed" size={24} color={colorPalette.blue300} />
                        </AttributeIcon>
                        <AttributeLabel>{bedrooms} Bedroom</AttributeLabel>
                    </AttributeItem>
                    <AttributeItem>
                        <AttributeIcon>
                            <MaterialIcon icon="bathtub" size={24} color={colorPalette.blue300} />
                        </AttributeIcon>
                        <AttributeLabel>{bathrooms} Bathroom</AttributeLabel>
                    </AttributeItem>
                    <AttributeItem>
                        <AttributeIcon>
                            <MaterialIcon icon="straighten" size={24} color={colorPalette.blue300} />
                        </AttributeIcon>
                        <AttributeLabel>{size} Sqft</AttributeLabel>
                    </AttributeItem>
                </AttributeRow>
                <PurchaseInfoRow>
                    <PurchaseInfoLabel>
                        <MaterialIcon icon="event_available" size={20} color={colorPalette.blue600} style="outlined" />
                        Kaufdatum
                    </PurchaseInfoLabel>
                    <PurchaseInfoValue>{purchaseDate}</PurchaseInfoValue>
                </PurchaseInfoRow>
                <PurchaseInfoRow>
                    <PurchaseInfoLabel>
                        <MaterialIcon icon="shoppingmode" size={20} color={colorPalette.blue600} style="outlined" />
                        Kaufpreis
                    </PurchaseInfoLabel>
                    <PurchaseInfoValue>AED {purchasePrice.toLocaleString()}</PurchaseInfoValue>
                </PurchaseInfoRow>
            </DetailsSection>
        </CardContainer>
    );
};

export default UnitDetailsCard;
