import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const SectionContainer = styled.div`
  background-color: ${colorPalette.white};
  padding: 64px 180px;
  text-align: center;
  border-bottom: 1px solid ${colorPalette.blue200};
`;

export const Title = styled.h2`
  color: ${colorPalette.textMain};
  ${typeScale.headingM}
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  color: ${colorPalette.textDark};
  ${typeScale.bodyMDMain}
  margin-bottom: 32px;
`;

export const PackageHeader = styled.div<{ premium: boolean }>`
  background-color: ${({ premium }) =>
    premium ? colorPalette.primaryDark : colorPalette.blue300};
  padding: 24px;
  gap: 24px;
  color: ${({ premium }) =>
    premium ? colorPalette.white : colorPalette.primaryDark};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PackageCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  overflow: hidden;
  border-radius: 8px;
  gap: 10px;
  text-align: left;
`;

export const PackageIcon = styled.img`
  height: 20px;
  width: 20px;
`;

export const PackageTitle = styled.h3`
  margin: 0;
  ${typeScale.headingSMMain}
`;

export const PackageSubtitle = styled.h3<{ premium: boolean }>`
  margin-bottom: 24px;
  color: ${({ premium }) =>
    premium ? colorPalette.blue300 : colorPalette.blue700};
  ${typeScale.bodySMMain}
`;

export const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${colorPalette.white};
  margin-bottom: 8px;
`;

export const PriceAmount = styled.span`
  ${typeScale.headingM}
  margin-right: 8px;
`;

export const PriceText = styled.span`
  ${typeScale.headingSMMain}
`;

export const TooltipIcon = styled.span`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.white};

  &:hover {
    color: #ededed;
  }
`;

export const PriceInfoDetail = styled.p<{ premium: boolean }>`
  margin-bottom: 4px;
  color: ${({ premium }) =>
    premium ? colorPalette.blue300 : colorPalette.blue600};
  ${typeScale.bodyXXSMain}
`;

export const FeatureList = styled.ul`
  flex-grow: 1;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const PackageFooter = styled.div`
  padding: 16px 24px;
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const FeatureItemTitle = styled.p`
  margin-bottom: 0;
  margin-left: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};
`;

export const IconWrapper = styled.div<{ premium: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: ${({ premium }) =>
    premium ? colorPalette.primaryDark : colorPalette.blue300};
  border-radius: 50%;
  cursor: pointer;
`;

export const ViewMoreLink = styled.a`
  color: ${colorPalette.blue500};
  text-decoration: none;
  padding-left: 24px;
  display: inline-block;
  margin-bottom: 16px;
  ${typeScale.bodySM}

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const InfoText = styled.p`
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS}
  margin-top: 24px;
`;

export const ClickableLink = styled.a`
  color: ${colorPalette.blue500};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Disclaimer = styled.p`
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXSMain}
  margin-top: 8px;
`;
