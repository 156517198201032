import React from 'react';
import {
    ModalContainer,
    Header,
    HeaderTitle,
    CloseButton,
    ProgressList,
    ProgressItem,
    ProgressCircle,
    ProgressLine,
    ProgressPercentage,
    ProgressDate,
    ProgressStatus,
    ProgressIcon,
    Footer,
    FooterLink,
    HeaderSubtitle,
    Overlay,
    ProgressItemDetail,
    StatusText,
} from './construction-progress-modal.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';

interface ProgressItem {
    percentage: number;
    date: string;
    status: string;
    reportLink?: string;
}

interface ConstructionProgressModalProps {
    title: string;
    subtitle: string;
    completionDate: string;
    progressItems: ProgressItem[];
    onClose: () => void;
}

const ConstructionProgressModal: React.FC<ConstructionProgressModalProps> = ({
    title,
    subtitle,
    completionDate,
    progressItems,
    onClose,
}) => {
    return (
        <Overlay onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <Header>
                    <div>
                        <HeaderTitle>{title}</HeaderTitle>
                        <HeaderSubtitle>Geplante Fertigstellung: {completionDate}</HeaderSubtitle>
                    </div>
                    <CloseButton onClick={onClose}>
                        <MaterialIcon icon="close" size={24} color={colorPalette.blue500} />
                    </CloseButton>
                </Header>
                <ProgressList>
                    <div style={{ display: 'grid', gridTemplateColumns: '40px 100px 1fr auto', alignItems: 'center', paddingLeft: 16 }}>
                        <div></div>
                        <StatusText isFirst={true}>Aktueller Status</StatusText>
                        <div></div>
                        <StatusText isFirst={true}>DLD Report</StatusText>
                    </div>
                    {progressItems.map((item, index) => (
                        <ProgressItem key={index} isLast={index === progressItems.length - 1}>
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <ProgressCircle isFirst={index === 0} />
                                <ProgressLine
                                    isTopItem={index === 0}
                                    isBottomItem={index === progressItems.length - 1}
                                />
                            </div>
                            <ProgressPercentage isActive={index === 0}>
                                {item.percentage}%
                            </ProgressPercentage>
                            <ProgressItemDetail>
                                <ProgressDate>{item.date}</ProgressDate>
                                <ProgressStatus isFirst={index === 0}>{item.status}</ProgressStatus>
                            </ProgressItemDetail>
                            {item.reportLink && (
                                <ProgressIcon href={item.reportLink}>
                                    <MaterialIcon icon="file_open" size={24} color={colorPalette.blue500} />
                                </ProgressIcon>
                            )}
                        </ProgressItem>
                    ))}
                </ProgressList>
                <Footer>
                    <FooterLink href="https://dubailand.gov.ae/en/eservices/real-estate-project-status-landing/real-estate-project-status" target="_blank">Details zu The Vyne im Dubai Land Department</FooterLink>
                    <MaterialIcon icon="open_in_new" size={20} color={colorPalette.blue500} />
                </Footer>
            </ModalContainer>
        </Overlay>
    );
};

export default ConstructionProgressModal;
