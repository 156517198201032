import React from 'react';
import {
    GoodToKnowContainer,
    ArticleCard,
    ArticleImage,
    ArticleContent,
    ArticleTitle,
    ArticleSubtitle,
    LearnMore,
    Title,
    ArticleAdditionalText,
    ArticleTitleWrapper
} from './good-to-know-articles.styles';
import { Row, Col } from 'react-bootstrap';

interface GoodToKnowArticle {
    id: number;
    image: string;
    title: string;
    subtitle: string;
    additionalText: string;
    linkText: string;
}

const mockArticles: GoodToKnowArticle[] = [
    {
        id: 1,
        image: 'https://via.placeholder.com/520x320',
        title: 'The Vyne',
        subtitle: 'Das Gebäude',
        additionalText: 'Residential Insights etc.',
        linkText: 'Mehr erfahren',
    },
    {
        id: 2,
        image: 'https://via.placeholder.com/520x320',
        title: 'Der Handover-Prozess',
        subtitle: 'Knowledge Hub',
        additionalText: 'Die Schritte für ein erfolgreiches Handover.',
        linkText: 'Mehr erfahren',
    },
];

const GoodToKnowArticles: React.FC = () => {
    return (
        <GoodToKnowContainer>
            <Title>Good to know</Title>
            <Row>
                {mockArticles.map((article) => (
                    <Col key={article.id} xs={12} md={6}>
                        <ArticleCard>
                            <ArticleImage src={article.image} alt={article.title} />
                            <ArticleContent>
                                <ArticleSubtitle>{article.subtitle}</ArticleSubtitle>
                                <ArticleTitleWrapper>
                                    <ArticleTitle>{article.title}</ArticleTitle>
                                    <ArticleAdditionalText>{article.additionalText}</ArticleAdditionalText>
                                </ArticleTitleWrapper>
                                <LearnMore>{article.linkText}</LearnMore>
                            </ArticleContent>
                        </ArticleCard>
                    </Col>
                ))}
            </Row>
        </GoodToKnowContainer>
    );
};

export default GoodToKnowArticles;
