import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const SectionContainer = styled.div`
  background-color: ${colorPalette.white};
  padding: 64px 180px;
  text-align: center;
  border-bottom: 1px solid ${colorPalette.blue200};
`;

export const Container = styled.div`
  background-color: ${colorPalette.white};
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 700px;
  margin: auto;
`;

export const OnboardingImage = styled.img`
  width: 300px;
  align-self: center;
`;

export const Title = styled.h2`
  color: ${colorPalette.textMain};
  ${typeScale.headingM}
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  color: ${colorPalette.textDark};
  ${typeScale.bodyMDMain}
  margin-bottom: 16px;
`;

export const CTAButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
`;
