import React from 'react';
import { IconType } from 'react-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TagContainer, TagLogo, TagTooltipIcon } from './tag.styles';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { colorPalette } from '../../utils/theme';
import LogoIcon from '../../assets/icons/logo-icon-white.png';

interface UnitStatusTagProps {
    label: string;
    type: 'unitStatus';
    variant: 'inProgress' | 'handover' | 'vacant' | 'rented' | 'completed';
    icon?: IconType;
    tooltip?: string;
}

interface PackageTagProps {
    label: string;
    type: 'package';
    variant: 'basic' | 'premium' | 'noManagement';
    icon?: IconType;
    tooltip?: string;
}

// Combine both prop types into a single type
type TagProps = UnitStatusTagProps | PackageTagProps;

// Define styles for unit status
const unitStatusStyles = {
    inProgress: { backgroundColor: colorPalette.successMedium, color: colorPalette.white },
    handover: { backgroundColor: colorPalette.successMedium, color: colorPalette.white },
    vacant: { backgroundColor: colorPalette.blue600, color: colorPalette.white },
    rented: { backgroundColor: colorPalette.successMedium, color: colorPalette.white },
    completed: { backgroundColor: colorPalette.blue600, color: colorPalette.white },
};

// Define styles for package tags
const packageStyles = {
    basic: { backgroundColor: colorPalette.blue900, color: colorPalette.white },
    premium: { backgroundColor: colorPalette.gold500, color: colorPalette.white },
    noManagement: { backgroundColor: colorPalette.blue600, color: colorPalette.white },
};

const Tag: React.FC<TagProps> = (props) => {
    let styles;

    // Handle unit status tags
    if (props.type === 'unitStatus') {
        styles = unitStatusStyles[props.variant];
    }
    // Handle package tags
    else if (props.type === 'package') {
        styles = packageStyles[props.variant];
    }

    const backgroundColor = styles?.backgroundColor ?? '#FFFFFF'; // Default white
    const color = styles?.color ?? '#000000'; // Default black

    return (
        <TagContainer backgroundColor={backgroundColor} color={color}>
            {/* Optional logo */}
            {props.type === "package" && <TagLogo src={LogoIcon} alt="logo" />}

            {/* Icon before label */}
            {props.icon && <props.icon style={{ marginRight: '8px' }} />}

            {props.label}

            {/* Optional tooltip */}
            {props.tooltip && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{props.tooltip}</Tooltip>}
                >
                    <TagTooltipIcon>
                        <IoInformationCircleOutline size={16} />
                    </TagTooltipIcon>
                </OverlayTrigger>
            )}
        </TagContainer>
    );
};

export default Tag;
