import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const SectionContainer = styled.div`
  background-color: ${colorPalette.white};
  padding: 64px 180px;
  text-align: center;
  border-bottom: 1px solid ${colorPalette.blue200};
`;

export const Container = styled.div`
  background-color: ${colorPalette.white};
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 700px;
  margin: auto;
`;

export const Title = styled.h2`
  color: ${colorPalette.textMain};
  ${typeScale.headingM}
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  color: ${colorPalette.textDark};
  ${typeScale.bodyMDMain}
  margin-bottom: 32px;
`;

export const UnitItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border: 1px solid ${colorPalette.blue500};
  padding: 24px;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: transform 0.3s ease-in-out;
  background-color: ${({ disabled }) =>
    disabled ? colorPalette.blue100 : colorPalette.white};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  &:hover {
    transform: ${({ disabled }) => (disabled ? "none" : "scale(1.03)")};
  }
`;

export const UnitRow = styled.div`
  display: flex;
  gap: 24px;
`;

export const UnitDetails = styled.div`
  gap: 4px;
`;

export const UnitTitle = styled.h4`
  text-align: start;
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingM}
`;

export const PropertyTitle = styled.h4`
  text-align: start;
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyLG}
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: ${colorPalette.textMain};
    ${typeScale.bodyMD}
  }
`;

export const NoManagementOption = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;

  span {
    color: ${colorPalette.textMain};
    ${typeScale.headingXXS}
  }
`;

export const CTAButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
  min-width: 200px;
`;
