import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-phone-number-input/style.css'
import 'material-symbols';

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// Routes
import ErrorPage from './routes/error-page';
import Root from './routes/root';
import LoginPage from './routes/login-page/login-page';
import ConstructionUpdatesPage from './routes/construction-updates-page';
import ConstructionUpdatePage from './routes/construction-update-page/construction-update-page';
import ProtectedRoute from './routes/protected-route';
import PageContainerWrapper from './routes/page-container-wrapper';
import InvestorDetailPage from './routes/investor-detail-page/investor-detail-page';
import FAQPage from './routes/faq-page';
import ResetPasswordPage from './routes/reset-password-page/reset-password-page';
import CoursePage from './routes/course-page';
import MyUnitsPage from './routes/my-units-page/my-units-page';
import CourseDetailPage from './routes/course-detail-page/course-detail-page';
import * as Sentry from "@sentry/react";
import UnitDetailPage from './routes/unit-detail-page/unit-detail-page';
import PackageSelectionPage from './routes/package-selection-page/package-selection-page';
import DashboardPage from './routes/dashboard-page';
import FinancialsPage from './routes/financials-page';

const isProduction = process.env.REACT_APP_ENV === "production";

Sentry.init({
  dsn: "https://352009d95c7053d2edcab1a5b0b50a3b@o4507089669849088.ingest.de.sentry.io/4507089726603344",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/danielgarofoli\.com\/investor-api/],
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-profile">
            <InvestorDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/my-units",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-units">
            <MyUnitsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/my-units/:unitId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="my-units">
            <UnitDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/construction-updates",
        element: <ProtectedRoute>
          <PageContainerWrapper id="construction-updates">
            <ConstructionUpdatesPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/construction-update/:updateId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="construction-update">
            <ConstructionUpdatePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/course",
        element: <ProtectedRoute>
          <PageContainerWrapper id="faq">
            <CoursePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/course/:itemId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="faq">
            <CourseDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/knowledge-hub",
        element: <ProtectedRoute>
          <PageContainerWrapper id="knowledge-hub">
            <FAQPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/packages",
        element: <ProtectedRoute>
          <PageContainerWrapper id="package-selection">
            <PackageSelectionPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/dashboard",
        element: <ProtectedRoute>
          <PageContainerWrapper id="dashboard">
            <DashboardPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/financials",
        element: <ProtectedRoute>
          <PageContainerWrapper id="financials">
            <FinancialsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/reset-password",
        element: <ResetPasswordPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
