import styled from 'styled-components';
import { colorPalette, typeScale } from '../../../../utils/theme';

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colorPalette.textMain};
  ${typeScale.headingSM}
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
`;

export const ProgressContainer = styled.div`
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const ProgressLink = styled.p`
  color: ${colorPalette.blue500};
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  ${typeScale.bodySM}

  &:hover {
    cursor: pointer;
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
`;

export const ProgressSegment = styled.div<{ filled: boolean; partial: number }>`
  flex: 1;
  height: 8px;
  background-color: ${({ filled }) =>
    filled ? colorPalette.primaryDark : colorPalette.blue100};
  background: ${({ filled, partial }) =>
    filled
      ? colorPalette.primaryDark
      : partial > 0
      ? `linear-gradient(to right, ${colorPalette.primaryDark} ${partial * 100}%, ${colorPalette.blue100} ${partial * 100}%)`
      : colorPalette.blue100};
  border-radius: 4px;
`;

export const ProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ProgressPercentage = styled.p`
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyXL}
  margin: 0;
`;

export const ProgressStatus = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;

  .current-amount {
    color: ${colorPalette.primaryDark};
  }

  .divider {
    color: ${colorPalette.primaryDark};
  }

  .total-amount {
    color: ${colorPalette.textDark};
  }

  ${typeScale.bodyXL}
`;

export const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  border-top: 1px solid ${colorPalette.blue100};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const DetailLabel = styled.p`
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
  margin: 0;
`;

export const DetailValue = styled.p`
  color: ${colorPalette.textMain};
  ${typeScale.bodyMD}
  margin: 0;
`;

export const LinkIcon = styled.div`
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
`;
