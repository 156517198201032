import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Container for the entire card + banner
export const Container = styled.div`
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  overflow: hidden;
`;

// Container for the entire card
export const UnitCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${colorPalette.white};
  overflow: hidden;
  position: relative;
  cursor: pointer;
`;

// Image container for the unit image
export const ImageContainer = styled.div`
  width: 320px;
  margin-right: 16px;
`;

// Content of the unit card
export const UnitContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 16px;
  height: 100%;
`;

// Header for the title, tags, and subtitle
export const UnitHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;

// New container for title and tags (they share one row)
export const TitleTagsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

// Title of the unit
export const UnitTitle = styled.h3`
  ${typeScale.headingM}
  color: ${colorPalette.textMain};
  margin: 0;
`;

// Tags container for unit status and package
export const UnitTagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

// Subtitle container with location icon
export const SubtitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px; /* Adds a small gap between the icon and the subtitle */
`;

// Subtitle for the property name
export const UnitSubtitle = styled.h4`
  ${typeScale.bodySMMain}
  color: ${colorPalette.textMain};
  margin: 0;
`;

// Details section
export const UnitDetails = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

// Layout for the statistics
export const UnitStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 8px;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: ${colorPalette.blue200};
`;

// Individual statistic item
export const UnitStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  ${typeScale.bodyXS}
  color: ${colorPalette.textMain};
`;

// Label for the statistics
export const UnitStatisticLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS}
`;

// Value of the statistic
export const UnitNumber = styled.p`
  ${typeScale.headingSMMain}
  color: ${colorPalette.textMain};
  margin: 0;
`;

// Layout for the unit status
export const UnitStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 16px 4px;
`;

// Individual unit status item
export const UnitStatusItem = styled.div`
  gap: 4px;
`;

// Label for the unit status item
export const UnitStatusLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;

/* CONSTRUCTION STYLES */
export const ProgressContainer = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ProgressTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProgressLabel = styled.span`
  font-weight: bold;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMD};
`;

export const ProgressDescription = styled.span`
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};
`;

export const ProgressPercentage = styled.span`
  font-weight: bold;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain};
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colorPalette.blue100};
  border-radius: 4px;
`;

export const ProgressBar = styled.div`
  height: 100%;
  background-color: ${colorPalette.successMedium};
  border-radius: 4px;
`;
