import React from 'react';
import {
    ModalContainer,
    Header,
    HeaderTitle,
    CloseButton,
    Footer,
    HeaderSubtitle,
    Overlay,
    FooterText,
    PaymentList,
    PaymentItem,
    PaymentIcon,
    PaymentInfo,
    PaymentTitle,
    PaymentDetail,
    PaymentAmount,
    PaymentPercentage,
} from './payment-plan-modal.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';

interface PaymentPlanItem {
    percentage: number;
    amount: number;
    status: string;
    title: string;
}

interface PaymentPlanModalProps {
    completionDate: string;
    paymentPlanItems: PaymentPlanItem[];
    onClose: () => void;
}

const PaymentPlanModal: React.FC<PaymentPlanModalProps> = ({
    completionDate,
    paymentPlanItems,
    onClose,
}) => {
    return (
        <Overlay onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <Header>
                    <div>
                        <HeaderTitle>Payment Plan: The Vyne</HeaderTitle>
                        <HeaderSubtitle>Geplante Fertigstellung: {completionDate}</HeaderSubtitle>
                    </div>
                    <CloseButton onClick={onClose}>
                        <MaterialIcon icon="close" size={24} color={colorPalette.blue500} />
                    </CloseButton>
                </Header>
                <PaymentList>
                    {paymentPlanItems.map((item, index) => (
                        <PaymentItem key={index}>
                            <div className="d-flex">
                                <PaymentIcon>
                                    <MaterialIcon icon="credit_card_clock" size={24} color={colorPalette.white} />
                                </PaymentIcon>
                                <PaymentInfo>
                                    <PaymentTitle>{item.title}</PaymentTitle>
                                    <PaymentDetail>{item.status}</PaymentDetail>
                                </PaymentInfo>
                            </div>
                            <div>
                                <PaymentAmount>AED {item.amount.toLocaleString()}</PaymentAmount>
                                <PaymentPercentage>{item.percentage}%</PaymentPercentage>
                            </div>
                        </PaymentItem>
                    ))}
                </PaymentList>
                <Footer>
                    <FooterText>Fortschritt im Paymentplan dient lediglich zur Information und spiegelt keine tatsächlichen Zahlungen wieder. Statements of Accounts können direkt bei Metrical angefragt werden.</FooterText>
                </Footer>
            </ModalContainer>
        </Overlay>
    );
};

export default PaymentPlanModal;
