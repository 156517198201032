import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

interface BannerProps {
  variant: "warning" | "info" | "neutral";
}

export const BannerContainer = styled.div<BannerProps>`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: ${({ variant }) =>
    variant === "warning"
      ? colorPalette.warningLight
      : variant === "info"
      ? colorPalette.blue500
      : colorPalette.blue300};
  color: ${({ variant }) =>
    variant === "warning" ? colorPalette.warningDark : colorPalette.white};
`;

export const Label = styled.span<BannerProps>`
  margin-left: 8px;
  ${typeScale.bodyMDMain}
  color: ${({ variant }) =>
    variant === "warning"
      ? colorPalette.warningDark
      : variant === "info"
      ? colorPalette.white
      : colorPalette.textMain};
`;

export const IconWrapper = styled.div<BannerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;

  svg {
    color: ${({ variant }) =>
      variant === "warning"
        ? colorPalette.warningDark
        : variant === "info"
        ? colorPalette.white
        : colorPalette.textMain};
  }
`;
