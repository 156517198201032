import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LogoMulticolor from '../../assets/images/logo/logo-multicolor.png';
import { logout } from '../../services/authService';
import { SidebarContainer, NavItem, LogoImage, SidebarHeader } from './sidebar.styles';
import MENU_ITEMS, { IMenuItem } from '../../config/menuConfig';
import { colorPalette } from '../../utils/theme';
import MaterialIcon from '../material-icon';

interface SidebarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarVisible, toggleSidebar }) => {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState<string>('Mein Profil');

    useEffect(() => {
        const findActiveItem = () => {
            const active = MENU_ITEMS.find((item) => location.pathname === item.path);
            return active ? active.name : '';
        };
        setActiveItem(findActiveItem());
    }, [location]);

    const handleItemClick = (item: IMenuItem) => {
        if (!item.isActivated) return;
        if (activeItem !== item.name) {
            setActiveItem(item.name);
            toggleSidebar();
        }
    };

    const onClickLogout = async () => {
        await logout();
    };

    return (
        <SidebarContainer isVisible={isSidebarVisible}>
            <SidebarHeader>
                <LogoImage src={LogoMulticolor} alt="Logo" />
            </SidebarHeader>
            <ul className="nav flex-column">
                {MENU_ITEMS.map((item) => {
                    const isActive = activeItem === item.name;

                    return (
                        <NavItem key={item.id} isActive={isActive} isDeactivated={!item.isActivated}>
                            <Link
                                to={item.isActivated ? item.path : '#'}
                                className={`nav-link ${!item.isActivated ? 'deactivated' : ''}`}
                                onClick={() => handleItemClick(item)}
                            >
                                    <MaterialIcon icon={item.icon} size={24} color={colorPalette.primaryDark} />
                                {item.name}
                            </Link>
                        </NavItem>
                    );
                })}
                {/*<LogoutButton>
                    <Link to="/login" className="nav-link" onClick={onClickLogout}>
                        <span className="icon me-2">
                            <IoExitOutline />
                        </span>
                        Abmelden
                    </Link>
                </LogoutButton>*/}
            </ul>
        </SidebarContainer>
    );
};

export default Sidebar;
