import React from 'react';
import {
    CardContainer,
    Header,
    HeaderTitle,
    ViewAllLink,
    Amount,
    StatusBadge,
    Details,
    DueDate,
    Label,
} from './next-rent-payment-card.styles';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import { formatMoney } from '../../../../utils/helpers';

interface NextRentPaymentCardProps {
    amount: number;
    status: string;
    dueDate: string;
    onViewAll: () => void;
}

const NextRentPaymentCard: React.FC<NextRentPaymentCardProps> = ({
    amount,
    status,
    dueDate,
    onViewAll,
}) => {
    return (
        <CardContainer>
            <Header>
                <HeaderTitle>Nächste Mietauszahlung</HeaderTitle>
                <ViewAllLink onClick={onViewAll}>
                    Alle Transaktionen
                    <MaterialIcon icon="arrow_outward" size={16} color={colorPalette.blue500} />
                </ViewAllLink>
            </Header>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                    <Amount>AED {formatMoney(amount)}</Amount>
                    <StatusBadge>{status}</StatusBadge>
                </div>
                <DueDate>{dueDate}</DueDate>
            </div>
            <Details>
                <Label>Nächste Auszahlung</Label>
                <Label>Fälligkeitsdatum</Label>
            </Details>
        </CardContainer>
    );
};

export default NextRentPaymentCard;
