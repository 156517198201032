import styled, { keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme"; // Adjust imports if you have a theme file

// Animation for the no units message
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Main container for the page
export const NoSelectContainer = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

// Header container for the page
export const HeaderContainer = styled.div`
  padding: 12px 16px;
  gap: 12px;
  margin-bottom: 20px;
`;

// Units title
export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingL}
`;

// Units subtitle
export const Subtitle = styled.p`
  margin: 0;
  margin-bottom: 8px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain}
`;

// Filter container for the page
export const FilterContainer = styled.div`
  display: flex;
  gap: 8px;
`;

// Loading container for Lottie animation
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

// No units available container with Lottie animation
export const NoUnitsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

// Message when no units are available
export const NoUnitsMessage = styled.p`
  font-size: 14px;
  opacity: 0;
  animation: ${fadeInUp} 1s forwards 1s;
  margin-top: -120px;
  text-align: center;
`;

// Row layout for the unit cards
export const Row = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
`;

// Column for individual unit cards
export const Col = styled.div`
  flex: 0 0 100%;
`;

// Spinner loader when fetching more units
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
