import { MaterialSymbol } from "material-symbols";

export interface IMenuItem {
  id: number;
  name: string;
  icon: MaterialSymbol;
  path: string;
  isVisible: boolean;
  isActivated: boolean;
}

const MENU_ITEMS: IMenuItem[] = [
  {
    id: 1,
    name: "Dashboard",
    icon: "grid_view",
    path: "/dashboard",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 2,
    name: "Meine Units",
    icon: "home",
    path: "/my-units",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 3,
    name: "Financials",
    icon: "table_chart_view",
    path: "/financials",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 4,
    name: "Knowledge Hub",
    icon: "school",
    path: "/knowledge-hub",
    isVisible: true,
    isActivated: true,
  },
  {
    id: 5,
    name: "Contact",
    icon: "call",
    path: "/contact",
    isVisible: true,
    isActivated: true,
  },
];

export default MENU_ITEMS;
